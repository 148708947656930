import { CustomButton } from "../../../../components/buttons/customButton";
import { UploadFile } from "../../../../components/form/upload_file";
import { PageLayout } from "../../../../components/layout/pageLayout";
import { SummeryLayout } from "../../../../components/summary-layout";
import { TransparentShadowSection } from "../../../../components/transparent-shadow";
import { handleError } from "../../../../helper";
import { ApiRequests } from "../../../../service/ApiRequests";
import {
  Await,
  LoaderFunction,
  defer,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Suspense, useEffect, useState } from "react";

export default function CreateSummaryPage() {
  const params = useParams();
  const data: any = useLoaderData();
  return (
    <PageLayout title="> Gestión Documentos / Generar nuevo resumen">
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={data?.summary}>
          {(summary) => (
            <SummeryLayout id={params.id} text={summary?.output ?? ""}>
              {/* <q> */}
              {summary?.output.split("\n").map((el, i) => {
                const El = i === 0 ? "h1" : "p";
                return (
                  <El
                    style={{
                      fontSize: i !== 0 ? "0.95rem" : "1.8rem",
                      marginBottom: i !== 0 ? "0.65rem" : "1.5rem",
                    }}
                    key={i}
                  >
                    {el}
                  </El>
                );
              })}
            </SummeryLayout>
          )}
        </Await>
      </Suspense>
    </PageLayout>
  );
}

async function getSummary(id: string) {
  try {
    const res = await ApiRequests.getSummary(id);
    return res.data;
  } catch (error) {
    throw error;
  }
}

const loader: LoaderFunction = ({ params }) => {
  return defer({
    summary: getSummary(params.id),
  });
};

export { loader as summaryLoader };
