import { GFontToDataURI } from "./fonts";
import { Buffer } from "buffer";
function getUnderscoreIndexes(str: string): [number, number][] {
  const indexes: [number, number][] = [];
  let underscoreCount = 0;
  let start = -1;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "_") {
      if (underscoreCount === 0) {
        start = i;
      }
      underscoreCount++;
    } else if (underscoreCount > 0) {
      if (start !== -1 && underscoreCount > 1) {
        indexes.push([start, i - 1]);
      }
      underscoreCount = 0;
      start = -1;
    }
  }

  if (start !== -1 && underscoreCount > 1) {
    indexes.push([start, str.length - 1]);
  }

  return indexes;
}

// If string has underscore input isolate it into next line
export function formatString(str: string): string {
  const indexes = getUnderscoreIndexes(str);
  // Check if the underscores are already in a new line
  let newStr = str;
  for (let i = 0; i < indexes.length; i++) {
    const [firstIndex, lastIndex] = indexes[i];
    if (
      firstIndex !== -1 &&
      lastIndex !== -1 &&
      str[firstIndex - 1] === "\n" &&
      str[lastIndex + 1] === "\n"
    ) {
      continue;
    }

    if (firstIndex !== -1 && lastIndex !== -1) {
      // Replace firstIndex-1 with \n
      newStr =
        newStr.substring(0, firstIndex - 1) +
        "\n" +
        newStr.substring(firstIndex, lastIndex + 2) +
        "\n" +
        newStr.substring(lastIndex + 2);
    }
  }
  return newStr;
}

export const convertTextToImage = (
  name: string = "HELLLO WOOORORJLD",
  fontSize: number = 20,
  fontFamily: string = "Italianno"
) =>
  new Promise<HTMLCanvasElement>(async (resolve, reject) => {
    try {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const svgElement =
        convertSvgStringToNode(`<svg viewbox="0 0 1000 250" xmlns="http://www.w3.org/2000/svg" width="1000" height="250" font-size="${
          fontSize * 5
        }" text-anchor="start">
        <text font-family="${fontFamily}, sans-serif" y="50%" dominant-baseline="middle" text-anchor="start" fill="#000">
          ${name}
        </text>
      </svg>`);
      const cssRules = await GFontToDataURI(
        `https://fonts.googleapis.com/css2?family=${fontFamily
          .split(" ")
          .join("+")}&display=swap`
      );
      let svgNS = "http://www.w3.org/2000/svg";
      // so let's append it in our svg node
      let defs = svgElement.createElementNS(svgNS, "defs");
      let style = svgElement.createElementNS(svgNS, "style");
      style.setAttribute("type", "text/css");
      style.innerHTML = cssRules.join("\n");
      defs.appendChild(style);
      svgElement.documentElement.appendChild(defs);
      console.log(svgElement.documentElement);
      const svg = Buffer.from(
        new XMLSerializer().serializeToString(svgElement.documentElement)
      ).toString("base64");
      let uri = `data:image/svg+xml;base64,${svg}`;
      let img = new Image();
      img.onload = function (e) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        resolve(canvas);
      };
      img.src = uri;
    } catch (error) {
      reject(error);
    }
  });

function convertSvgStringToNode(svgString) {
  return new DOMParser().parseFromString(svgString, "image/svg+xml");
}
