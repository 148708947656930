import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/buttons/customButton";
import { FloatingInputField } from "../../components/form/floating_input_filed";
import { handleError } from "../../helper";
import { ApiRequests } from "../../service/ApiRequests";
import { ForgetPassword, LoginCredentials } from "../../types/auth";

export default function ForgetPasswordPage() {
  const [error, setError] = useState("");
  const router = useNavigate();
  const [loading, setLoadng] = useState(false);

  const { handleSubmit, control } = useForm<ForgetPassword>();
  const submit: SubmitHandler<LoginCredentials> = async (values) => {
    try {
      setLoadng(true);
      await ApiRequests.forgotPassword(values);
      setLoadng(false);
    } catch (error) {
      setLoadng(false);
      handleError({ error, setError, router });
    }
  };
  return (
    <>
      <p>Enter email to get reset password code.</p>
      <form className="row g-4" onSubmit={handleSubmit(submit)}>
        <div>
          <FloatingInputField
            type="email"
            id="email"
            label={"Email"}
            required
            name="email"
            control={control}
          />
        </div>

        <div>
          <CustomButton
            type="submit"
            value={loading ? "Sending..." : "Send"}
            className="w-100 btn btn-dark py-3"
          />
        </div>
      </form>
    </>
  );
}
