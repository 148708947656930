import { DocumentCard } from "../../../components/document-card";
import { SelectField } from "../../../components/form/select_field";
import { UploadFile } from "../../../components/form/upload_file";
import { PageLayout } from "../../../components/layout/pageLayout";
import { handleError } from "../../../helper";
import { ApiRequests } from "../../../service/ApiRequests";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TfiAngleDown } from "react-icons/tfi";
import { toast } from "react-hot-toast";
import { initialValue } from "../../../util-constants";
import { Link } from "react-router-dom";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
type SortBy =
  | "createdAt"
  | "updatedAt"
  | "name"
  | "expirationDate"
  | "signatureDate"
  | "type";
type Order = "asc" | "desc";
type SortByOrder = `${SortBy}:${Order}`;
type DefaultValues = {
  sortBy: SortBy;
  order: Order;
};
const sortOptions: {
  name: string;
  value: SortBy;
}[] = [
  { name: "Created At", value: "createdAt" },
  { name: "Last Updated", value: "updatedAt" },
  { name: "Name", value: "name" },
  { name: "Expiration Date", value: "expirationDate" },
  { name: "Signature Date", value: "signatureDate" },
  { name: "Type", value: "type" },
];

interface FuncType {
  page: number;
  sortBy: SortByOrder;
}

export default function DocumentPage() {
  const [documents, setDocuments] = useState(initialValue);
  const [summaries, setSummaries] = useState(initialValue);
  const [contracts, setContracts] = useState(initialValue);
  const [loadingSummaries, setLoadingSummaries] = useState(true);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [loadingContracts, setLoadingContracts] = useState(true);
  const { control, watch, setValue } = useForm<DefaultValues>({
    defaultValues: {
      sortBy: "createdAt",
      order: "asc",
    },
  });
  const sortBy = watch("sortBy");
  const order = watch("order");
  async function getContracts({ page, sortBy }: FuncType) {
    try {
      setLoadingContracts(true);
      const res = (await ApiRequests.getContracts({ page, sortBy })).data;
      if (res?.page == 1) {
        setContracts(res);
      } else if (res?.page > 1) {
        setContracts({
          ...contracts,
          ...res,
          results: contracts.results.concat(res.results),
        });
      }
      setLoadingContracts(false);
      return res;
    } catch (error) {
      setLoadingContracts(false);
      toast.error(
        error?.response?.data?.message ??
          error.message ??
          "Something went wrong"
      );
    }
  }
  async function getDocuments({ page, sortBy }: FuncType) {
    try {
      setLoadingDocuments(true);
      const res = (await ApiRequests.getDocuments({ page, sortBy })).data;
      if (res?.page == 1) {
        setDocuments(res);
      } else if (res?.page > 1) {
        setDocuments({
          ...documents,
          ...res,
          results: documents.results.concat(res.results),
        });
      }
      setLoadingDocuments(false);
      return res;
    } catch (error) {
      setLoadingDocuments(false);
      toast.error(
        error?.response?.data?.message ??
          error.message ??
          "Something went wrong"
      );
      // setErrorDocument(error?.response?.data?.message ?? "Something went wrong")
    }
  }
  async function getSummaries({ page, sortBy }: FuncType) {
    try {
      setLoadingSummaries(true);
      const res = (await ApiRequests.getSummaries({ page, sortBy })).data;
      if (res?.page == 1) {
        setSummaries(res);
      } else if (res?.page > 1) {
        setSummaries({
          ...summaries,
          ...res,
          results: summaries.results.concat(res.results),
        });
      }
      setLoadingSummaries(false);
      return res;
    } catch (error) {
      setLoadingSummaries(false);
      toast.error(
        error?.response?.data?.message ??
          error.message ??
          "Something went wrong"
      );
    }
  }
  useEffect(() => {
    getDocuments({ page: 1, sortBy: `${sortBy}:${order}` });
    getSummaries({ page: 1, sortBy: `${sortBy}:${order}` });
    getContracts({ page: 1, sortBy: `${sortBy}:${order}` });
  }, [sortBy, order]);

  return (
    <PageLayout title="S> Tu área personal / Documentos">
      <div className="d-flex justify-content-between flex-wrap gap-4 mb-4">
        <div className=" d-flex gap-4 gap-lg-5 flex-wrap justify-content-center justify-content-lg-start">
          <div className="d-flex align-items-center gap-3">
            <p className="m-0">Ordenar por</p>
            <SelectField
              control={control}
              name={"sortBy"}
              options={sortOptions}
            />
          </div>
          <div className="d-flex align-items-center gap-3">
            <p className="m-0">Orden</p>
            <button
              className="btn btn-outline-dark p-2"
              onClick={() => {
                setValue("order", order === "asc" ? "desc" : "asc");
              }}
              style={{
                aspectRatio: "1/1",
                display: "flex",
              }}
            >
              {order === "asc" ? (
                <AiOutlineSortAscending size={20} />
              ) : (
                <AiOutlineSortDescending size={20} />
              )}
            </button>
          </div>
        </div>
        <div>
          <Link to={"/user/legal-document"}>
            <UploadFile
              onChange={(e) => e.preventDefault()}
              showIconsOnly={true}
            />
          </Link>
        </div>
      </div>
      {loadingContracts || loadingDocuments || loadingSummaries ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="row g-4 justify-content-center">
          {renderWithCheck(
            <>
              {documents?.results?.map((data, index) => (
                <div className="col-10 col-sm-6 col-md-4 col-xl-3" key={index}>
                  <DocumentCard data={data} />
                </div>
              ))}
            </>,
            documents?.results?.length > 0
          )}
          {renderWithCheck(
            <>
              {contracts?.results?.map((data, index) => (
                <div className="col-10 col-sm-6 col-md-4 col-xl-3" key={index}>
                  <DocumentCard data={{ ...data, dataType: "contract" }} />
                </div>
              ))}
            </>,
            contracts?.results?.length > 0
          )}
          {renderWithCheck(
            <>
              {summaries?.results?.map((data, index) => (
                <div className="col-10 col-sm-6 col-md-4 col-xl-3" key={index}>
                  <DocumentCard data={{ ...data, dataType: "signature" }} />
                </div>
              ))}
            </>,
            summaries?.results?.length > 0
          )}
          {documents.results.length === 0 &&
            contracts.results.length === 0 &&
            summaries.results.length === 0 && (
              <div className="col-12 text-center py-4">
                <p className="m-0 h6">No hay documentos</p>
              </div>
            )}
          {(contracts.page < contracts.totalPages ||
            documents.page < documents.totalPages ||
            summaries.page < summaries.totalPages) && (
            <div className="col-12 text-center">
              <button
                onClick={() => {
                  let getPagedDocuments;
                  if (documents.page < documents.totalPages)
                    getPagedDocuments = () =>
                      getDocuments({
                        page: documents.page + 1,
                        sortBy: `${sortBy}:${order}`,
                      });
                  else getPagedDocuments = () => void 0;
                  let getPagedContracts;
                  if (contracts.page < contracts.totalPages)
                    getPagedContracts = () =>
                      getContracts({
                        page: contracts.page + 1,
                        sortBy: `${sortBy}:${order}`,
                      });
                  else getPagedContracts = () => void 0;
                  let getPagedSummaries;
                  if (summaries.page < summaries.totalPages)
                    getPagedSummaries = () =>
                      getSummaries({
                        page: summaries.page + 1,
                        sortBy: `${sortBy}:${order}`,
                      });
                  else getPagedSummaries = () => void 0;
                  Promise.all([
                    getPagedDocuments(),
                    getPagedContracts(),
                    getPagedSummaries(),
                  ]);
                }}
                className="btn btn-link"
              >
                <TfiAngleDown />
              </button>
            </div>
          )}
        </div>
      )}
    </PageLayout>
  );
}

const renderWithCheck = (Component: React.ReactNode, condition: boolean) => {
  if (condition) {
    return Component;
  }
  return null;
};
