import { FormFieldLayout } from "../form_field_layout";
import React from "react";
import { Control, Controller, Path } from "react-hook-form";
interface CheckboxFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fullWidth?: boolean;
  paragraph: string;
}

export const CheckboxField = <T extends {}>({
  label,
  fullWidth,
  value,
  ...props
}: CheckboxFieldProps & {
  control: Control<T>;
  name: Path<T>;
}) => {
  return (
    <FormFieldLayout fullWidth={fullWidth} label={label}>
      <div className="form-check">
        <Controller
          name={props.name}
          control={props.control}
          render={({ field }) => (
            <input
              {...props}
              {...field}
              type={"checkbox"}
              id={props.id}
              className="paragraph text-secondary form-check-input"
            />
          )}
        />
        <label
          htmlFor={props.id}
          className="form-check-label paragraph text-secondary"
        >
          {value}
        </label>
      </div>
    </FormFieldLayout>
  );
};
