import { Await, LoaderFunction, defer, useLoaderData } from "react-router-dom";
import { PageLayout } from "../../../../components/layout/pageLayout";
import { SummeryLayout } from "../../../../components/summary-layout";
import { ApiRequests } from "../../../../service/ApiRequests";
import { formatString } from "utils/functions";

export default function ContractGeneratedPage() {
  const data: any = useLoaderData();
  return (
    <PageLayout title="> Gestión Documentos / Generar nuevo contrato ">
      <Await resolve={data?.contract}>
        {(data) => (
          <SummeryLayout
            id={data.id}
            title={data.name}
            shareButton={true}
            text={formatString(data?.output)}
          >
            {formatString(data?.output)
              ?.split?.("\n")
              .map((el, i) => {
                const El = i === 0 ? "h1" : "p";
                return (
                  <El
                    style={{
                      fontSize: i !== 0 ? "0.95rem" : "1.8rem",
                      marginBottom: i !== 0 ? "0.65rem" : "1.5rem",
                    }}
                    key={i}
                  >
                    {el}
                  </El>
                );
              })}
          </SummeryLayout>
        )}
      </Await>
    </PageLayout>
  );
}

export const contractLoader: LoaderFunction = async ({ params }) => {
  try {
    return defer({
      contract: (async function () {
        return (await ApiRequests.getContract({ id: params.id })).data;
      })(),
    });
  } catch (error) {
    return { status: 400, data: error };
  }
};
