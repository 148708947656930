import React, { ReactNode, FC } from "react";

interface PageLayoutProps {
  title: string | ReactNode;
  children: ReactNode;
}
export const PageLayout: FC<PageLayoutProps> = ({ title, children }) => {
  return (
    <>
      <h1 className="mb-5">{title}</h1>
      <div className="row justify-content-center">
        <div className="col-xl-10">{children}</div>
      </div>
    </>
  );
};
