import { CreateLegalDocument, ForgetPassword, ResetPassword } from "types/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import { SweetAlertProps } from "react-bootstrap-sweetalert/dist/types";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-hot-toast";
import apiService, { safeJSONParse } from ".";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../config/firebase";
import { servicePaths } from "../util-constants";
import { router } from "App";
import loadingConstants from "constants/loading";
import confirmationConstants from "constants/confirm";

const calculateTimeFromSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const mins = calculateMinutes(minutes);
  const secs = calculateSeconds(remainingSeconds);
  return `${mins ? `${mins}m` : ""} ${secs ? `${secs}s` : ""}`.trim();
};

const calculateMinutes = (minutes: number) => {
  if (minutes === 0) return "";
  if (minutes > 0 && minutes < 10) return `0${minutes}`;
  return minutes.toString();
};

const calculateSeconds = (seconds: number) => {
  if (seconds === 0) return "";
  if (seconds > 0 && seconds < 10) return `0${seconds}`;
  return seconds.toString();
};

const withToaster = async (
  func: (...args: any[]) => Promise<any>,
  id: string,
  states: Options
) => {
  let t: NodeJS.Timer;
  try {
    let INTERVAL_IN_SECONDS = 0;
    let netEstimatedTime: number;
    if ("estimatedTime" in states) {
      netEstimatedTime = states.estimatedTime;
    }
    const refreshTimer = () => {
      if (!netEstimatedTime) return;
      t = setTimeout(() => {
        if (
          netEstimatedTime &&
          INTERVAL_IN_SECONDS &&
          netEstimatedTime <= INTERVAL_IN_SECONDS
        ) {
          netEstimatedTime = 0;
          clearTimeout(t);
          return;
        }
        INTERVAL_IN_SECONDS = Math.floor(Math.random() * 5) + 1;
        toast.loading(
          `${states.loading} ${
            netEstimatedTime
              ? `${calculateTimeFromSeconds(netEstimatedTime)} remaining`
              : ""
          }`,
          { id }
        );
        netEstimatedTime -= INTERVAL_IN_SECONDS;
        refreshTimer();
      }, 1000 * INTERVAL_IN_SECONDS);
    };
    toast.loading(
      `${states.loading} ${
        netEstimatedTime
          ? `${calculateTimeFromSeconds(netEstimatedTime)} remaining`
          : ""
      }`.trim(),
      { id }
    );
    refreshTimer();
    const res = await func();
    clearTimeout(t);
    toast.success(states.success, { id });
    return res;
  } catch (error) {
    clearTimeout(t);
    if (
      error?.request?.responseURL?.includes(servicePaths.refreshTokens) &&
      error.code === 401
    ) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          error?.message ||
          states.error ||
          "Something went wrong",
        { id }
      );
    } else if (error.code !== 401) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data ||
          error?.message ||
          states.error ||
          "Something went wrong",
        { id }
      );
    } else if (error instanceof Error) {
      toast.error(error.message, { id });
    }
    throw error;
  }
};

type Confirmation =
  (typeof confirmationConstants)[keyof typeof confirmationConstants];

const confirmationPromise = async (
  func: (...args: any) => any,
  confirm: Confirmation,
  props?: Partial<SweetAlertProps>
) =>
  new Promise((resolve, reject) => {
    confirmAlert({
      ...confirm,
      customUI: ({ onClose, message, title }) => (
        // @ts-ignore
        <SweetAlert
          showCancel
          // Yes in spanish
          confirmBtnText="Si"
          // No in spanish
          cancelBtnText="No"
          confirmBtnBsStyle="info"
          info
          {...props}
          title={title}
          onConfirm={() => {
            onClose();
            resolve(func());
          }}
          onCancel={() => {
            reject(new Error("User cancelled the action"));
            onClose();
          }}
          cancelBtnStyle={{
            textDecoration: "none",
            color: "black",
          }}
        >
          {message}
        </SweetAlert>
      ),
    });
  });
const withConfirmAction = async (
  func: (...args: any) => any,
  confirm: Confirmation,
  props?: Partial<SweetAlertProps>
) => {
  try {
    const res = await confirmationPromise(func, confirm, props);
    return res;
  } catch (error) {
    throw error;
  }
};

function Confirm({ title, message }: Confirmation) {
  return function (
    _target: Object,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {
      const res = await withConfirmAction(
        () => originalMethod.apply(this, args),
        { title, message },
        {
          info: true,
        }
      );
      return res;
    };
    return descriptor;
  };
}

type Prettify<T> = {
  [P in keyof T]: T[P] extends (...args: any[]) => any
    ? (...args: Parameters<T[P]>) => ReturnType<T[P]>
    : T[P];
} & {};

type Options = Prettify<
  (typeof loadingConstants)[keyof typeof loadingConstants] & {
    error?: string;
  }
>;

function Toast(options: Options) {
  return function (
    _target: Object,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>
  ) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {
      const res = await withToaster(
        () => originalMethod.apply(this, args),
        propertyKey,
        options
      );
      return res;
    };
    return descriptor;
  };
}

export class ApiRequestsClass {
  @Toast(loadingConstants.login)
  login(data: { email: string; password: string }) {
    return apiService.post(servicePaths.login, data, { withCredentials: true });
  }
  @Toast(loadingConstants.sendEmail)
  forgotPassword(data: ForgetPassword) {
    return apiService.post(servicePaths.forgotPassword, data, {
      withCredentials: true,
    });
  }
  @Confirm(confirmationConstants.resetPassword)
  @Toast(loadingConstants.resetPassword)
  async resetPassword(data: ResetPassword, params: { token: string }) {
    const res = await apiService.post(servicePaths.resetPassword, data, {
      withCredentials: true,
      params,
    });
    router.navigate("/");
    return res;
  }
  @Toast(loadingConstants.logout)
  logout() {
    return apiService.post(servicePaths.logout, {}, { withCredentials: true });
  }
  @Toast(loadingConstants.register)
  register(data: { name: string; email: string; password: string }) {
    return apiService.post(servicePaths.register, data, {
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.sendVerificationEmail)
  sendVerificationEmail() {
    return apiService.post(
      servicePaths.sendVerificationEmail,
      {},
      {
        withCredentials: true,
      }
    );
  }
  @Toast(loadingConstants.verifyEmail)
  verifyEmail(params: { token: string }) {
    return apiService.post(
      servicePaths.verifyEmail,
      {},
      {
        params,
        withCredentials: true,
      }
    );
  }
  authenticate() {
    if (!safeJSONParse(localStorage.getItem("access-token"))) return;
    return apiService.get(servicePaths.authenticate, {
      withCredentials: true,
    });
  }
  refreshTokens() {
    return apiService.post(
      servicePaths.refreshTokens,
      {},
      { withCredentials: true }
    );
  }
  // generate ai contract
  @Confirm(confirmationConstants.generateContract)
  @Toast(loadingConstants.generateContract)
  async generate(data: CreateLegalDocument) {
    const res = await apiService.post(servicePaths.generate, data, {
      withCredentials: true,
    });
    router.navigate(`/user/contract/${res.data.id}`);
    return res;
  }
  // profile
  @Toast(loadingConstants.updateProfile)
  updateProfile(data: any, id: string) {
    return apiService.patch(servicePaths.users + "/" + id, data, {
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.uploadAvatar)
  uploadAvatar(data: any) {
    return apiService.post(servicePaths.avatar, data, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  // document
  @Toast(loadingConstants.createDocument)
  createDocuments(data: any) {
    return apiService.post(servicePaths.documents, data, {
      withCredentials: true,
    });
  }
  getDocuments(params: any) {
    return apiService.get(servicePaths.documents, {
      params,
      withCredentials: true,
    });
  }
  getDocument(id: string) {
    return apiService.get(servicePaths.documents + "/" + id, {
      withCredentials: true,
    });
  }
  // contract
  getContracts(params: any) {
    return apiService.get(servicePaths.contracts, {
      params,
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.fetchContract)
  getContract(data: { id: string }) {
    return apiService.get(servicePaths.contracts + "/" + data.id, {
      withCredentials: true,
    });
  }
  // summaries
  @Toast(loadingConstants.createSummary)
  async createSummary(data: any) {
    const res = await apiService.post(servicePaths.summaries, data, {
      withCredentials: true,
    });
    router.navigate(`/user/summary/${res.data.id}`);
    return res;
  }
  getSummaries(params: any) {
    return apiService.get(servicePaths.summaries, {
      params,
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.fetchSummary)
  getSummary(id: string) {
    return apiService.get(servicePaths.summaries + "/" + id, {
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.createSession)
  createCheckoutSession(data: {
    plan: string;
    successUrl: string;
    failureUrl: string;
  }) {
    return apiService.post(servicePaths.checkout, data, {
      withCredentials: true,
    });
  }
  @Toast(loadingConstants.uploadSummary)
  async uploadSummary(file: File) {
    const pdfRef = ref(storage, `summaries/${file.name}`);
    await uploadBytes(pdfRef, file);
    const uri = await getDownloadURL(pdfRef);
    return uri;
  }
  @Toast(loadingConstants.uploadAvatar)
  async uploadAvatarToFirebase(file: File) {
    const mountainsRef = ref(storage, `profile/${file.name}`);
    await uploadBytes(mountainsRef, file);
    const imageUrl = await getDownloadURL(mountainsRef);
    return imageUrl;
  }

  async getMonthlyDocumentsCount() {
    const res = await apiService.get(servicePaths.monthlyDocumentsCount, {
      withCredentials: true,
    });
    return res?.data;
  }
  async getMonthlyContractsCount() {
    const res = await apiService.get(servicePaths.monthlyContractsCount, {
      withCredentials: true,
    });
    return res?.data;
  }

  async getMonthlySummariesCount() {
    const res = await apiService.get(servicePaths.monthlySummariesCount, {
      withCredentials: true,
    });
    return res?.data;
  }
  async getSubscriptionCounts() {
    const res = await apiService.get(servicePaths.counts, {
      withCredentials: true,
    });
    return res?.data;
  }
  @Toast(loadingConstants.gettingPdf)
  async getPDF(id: string) {
    const reference = ref(storage, `shared/${id}`);
    const url = await getDownloadURL(reference);
    return url;
  }
  async uploadPDF(file: Blob, id: string) {
    const pdfRef = ref(storage, `shared/${id}`);
    await uploadBytes(pdfRef, file);
    const uri = await getDownloadURL(pdfRef);
    return uri;
  }
}

export const ApiRequests = new ApiRequestsClass();
