import React, { FC } from "react";
interface FloatingSelectFieldProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: React.ReactNode;
  dataFormat?: string;
  control?:any
}
export const FloatingSelectField: FC<FloatingSelectFieldProps> = ({
  label,
  options,
  dataFormat,
  ...props
}) => {
  return (
    <div className="form-floating">
      <select className="form-select" {...props}>
        {options}
      </select>
      <label htmlFor={props.id}>{label}</label>
    </div>
  );
};
