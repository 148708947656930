import { CustomButton } from "../../components/buttons/customButton";
import { FloatingInputField } from "../../components/form/floating_input_filed";
import { ApiRequests } from "../../service/ApiRequests";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import { LoginCredentials } from "../../types/auth";
import { handleError } from "../../helper";
// import { handleError } from "../../helper";
// import { Route } from "next";
const wait = (time: number) => new Promise((res) => setTimeout(res, time));
const LoginField = FloatingInputField<LoginCredentials>;
export default function LoginPage() {
  const [error, setError] = useState("");
  const [loading, setLoadng] = useState(false);
  const router = useNavigate();
  const { handleSubmit, control } = useForm<LoginCredentials>();
  const submit: SubmitHandler<LoginCredentials> = async (values) => {
    try {
      setLoadng(true);
      const res = await ApiRequests.login(values);
      setLoadng(false);
      localStorage.setItem(
        "access-token",
        JSON.stringify(res?.data.access.token)
      );
      await wait(200);
      router("/user");
    } catch (error) {
      setLoadng(false);
      handleError({ error, setError, router });
    }
  };
  return (
    <>
      <div className="mb-4">
        <h4>Request Early Access</h4>
        <p className="text-secondary">
          Subscribe to be eligible for early access membership
        </p>
      </div>
      <form className="row g-4" onSubmit={handleSubmit(submit)}>
        <div>
          <LoginField
            type="email"
            id="email"
            label={"Email"}
            required
            name="email"
            control={control}
          />
        </div>
        <div>
          <LoginField
            type="password"
            id="password"
            label={"Password"}
            required
            name="password"
            control={control}
          />
          <div className="text-end mt-2">
            <Link to="/forget-password" className="text-primary paragraph">
              Forget Password?
            </Link>
          </div>
        </div>
        <div>
          {error && <p>{error}</p>}
          <CustomButton
            type="submit"
            value={loading ? "Logging In..." : "Login"}
            className="w-100 btn btn-dark py-3"
          />
        </div>
        <p className="text-center">
          {`Don't have an account ? `}
          <Link to={"/register"} className="text-primary">
            Register Account
          </Link>
        </p>
      </form>
    </>
  );
}
