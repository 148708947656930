import React, { FC } from "react";
import { Controller, Control, Path } from "react-hook-form";
interface FloatingInputFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> {
  label: string;
}

export const FloatingInputField = <T extends {} = {}>({
  label,
  ...props
}: FloatingInputFieldProps & {
  control?: Control<T>;
  name?: Path<T> | string;
}) => {
  return (
    <div className="form-floating">
      {props.control ? (
        <Controller
          name={props.name as Path<T>}
          control={props.control}
          render={({ field, fieldState }) => (
            <>
              <input
                {...field}
                type={props.type}
                className="form-control border"
                id={props.id}
                placeholder={props.placeholder}
                required={props.required}
              />
              {fieldState.error?.message && (
                <small
                  id={`${props.name}-error`}
                  className="form-text text-danger"
                >
                  {fieldState.error?.message}
                </small>
              )}
            </>
          )}
        />
      ) : (
        <input className="form-control border" {...props} />
      )}
      <label htmlFor={props.id}>{label}</label>
    </div>
  );
};
