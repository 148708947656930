import constractImg from "../../assets/images/icons/contract.png";
import uploadImg from "../../assets/images/icons/upload.png";
import signatureImg from "../../assets/images/icons/signature.png";
import { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
type DocumentCardProps = {
  data: any;
};
export const DocumentCard: FC<DocumentCardProps> = ({ data }) => {
  return (
    <Link
      to={
        data.dataType === "signature"
          ? "/user/summary/" + data?.id
          : data.dataType === "contract"
          ? "/user/contract/" + data?.id
          : "/user/legal-document/" + data?.id
      }
    >
      <div className="p-3 bg-white shadow rounded-3 text-center h-100">
        <img
          alt={data.dataType}
          src={
            data.dataType == "signature"
              ? signatureImg
              : data.dataType == "contract"
              ? constractImg
              : uploadImg
          }
          height={75}
          className="mb-2"
        />
        <p
          style={{
            fontSize: "0.8rem",
            marginBottom: "0.5rem",
          }}
          className="text-truncate"
          title={data?.name}
        >
          {data?.name}
        </p>
        {data.type && (
          <p
            style={{
              fontSize: "0.8rem",
              marginBottom: "0.1rem",
            }}
          >
            Tipo: {data.type}
          </p>
        )}
        <p
          style={{
            fontSize: "0.65rem",
            marginBottom: "0rem",
          }}
        >
          Fecha: {moment(data?.createdAt).fromNow()}
        </p>
      </div>
    </Link>
  );
};
