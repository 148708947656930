import moment from "moment";
import "moment/locale/es";
import NewContractPage from "pages/user/new-contract/page";
import { Suspense, lazy, useCallback, useEffect } from "react";
import {
  Await,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ApiRequests } from "service/ApiRequests";
import AuthLayout from "./components/layout/auth-layout";
import ForgetPassword from "./pages/forget-password/page";
import LoginPage from "./pages/login/index";
import Register from "./pages/register/page";
import ResetPassword from "./pages/reset-password/page";
import ContractGeneratedId, {
  contractLoader,
} from "./pages/user/contract-generated/[id]/page";
import CreateSummaryId, {
  summaryLoader,
} from "./pages/user/create-summary/[id]/page";
import CreateSummary from "./pages/user/create-summary/page";
import Documents from "./pages/user/documents/page";
import ExpirationCalendar from "./pages/user/expiration-calendar/page";
import PageOutlet from "./pages/user/layout";
import LegalDocumentId from "./pages/user/legal-document/[id]/page";
import LegalDocument from "./pages/user/legal-document/page";
import User, { userLoader } from "./pages/user/page";
import Pricing from "./pages/user/pricing/page";
import Profile from "./pages/user/profile/page";
import VerificationCode from "./pages/verification-code/page";
import VerifyEmail from "./pages/verify-email/page";
import { sharedDocumentLoader } from "pages/share/contract/[id]";
const SharedContractDocument = lazy(() => import("pages/share/contract/[id]"));
moment.locale("es");
const AuthPageOutlet = () => {
  const data: any = useLoaderData();
  return (
    <Suspense
      fallback={
        <div className="spinner-container">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      }
    >
      <Await errorElement={<InnerOutlet />} resolve={data?.isAuth}>
        {(isAuth) => <InnerOutlet {...isAuth} />}
      </Await>
    </Suspense>
  );
};

const InnerOutlet = (data) => {
  const navigate = useNavigate();
  const { hash, search, state } = useLocation();
  const navigateTo = useCallback(
    (path = window.location.pathname) => {
      navigate({ pathname: path, search, hash }, { replace: true, state });
    },
    [search, hash, state]
  );
  useEffect(() => {
    if (window.location.pathname.includes("share")) {
      return;
    }
    if (data) {
      if (data?.status >= 200 && data?.status < 300) {
        if (!window.location.pathname.includes("user")) {
          navigateTo("/user");
        }
      } else {
        if (window.location.pathname.includes("user")) {
          navigateTo("/");
        } else {
          navigateTo();
        }
      }
    } else {
      if (window.location.pathname.includes("user")) {
        navigateTo("/");
      } else {
        navigateTo();
      }
    }
  }, [navigateTo]);
  return <Outlet />;
};

const authLoader = () => {
  return defer({
    isAuth: ApiRequests.authenticate(),
  });
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" loader={authLoader} element={<AuthPageOutlet />}>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<Register />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route
          path="verification-code"
          element={
            <>
              {" "}
              <VerificationCode />{" "}
            </>
          }
        />
      </Route>
      <Route path="user" element={<PageOutlet />}>
        <Route index loader={userLoader} element={<User />} />
        <Route path="new-contract" element={<NewContractPage />} />
        <Route path="documents" element={<Documents />} />
        <Route path="expiration-calendar" element={<ExpirationCalendar />} />
        <Route path="legal-document" element={<LegalDocument />} />
        <Route path="legal-document/:id" element={<LegalDocumentId />} />
        <Route path="create-summary" element={<CreateSummary />} />
        <Route
          path="summary/:id"
          loader={summaryLoader}
          element={<CreateSummaryId />}
        />
        <Route
          loader={contractLoader}
          path="contract/:id"
          element={<ContractGeneratedId />}
          errorElement={<ContractGeneratedId />}
        />
        <Route path="profile" element={<Profile />} />
        <Route path="pricing" element={<Pricing />} />
      </Route>
      <Route path="share" element={<Outlet />}>
        <Route
          path="contract/:id"
          loader={sharedDocumentLoader}
          element={<SharedContractDocument />}
        />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
