import React, { useEffect } from "react";
import { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/legaly.png";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoMdPricetag } from "react-icons/io";
import { ApiRequests } from "../../service/ApiRequests";
import { handleError } from "../../helper";
const sidebarLinks: {
  name: string;
  subLinks: { name: string; url: string }[];
}[] = [
  {
    name: "Gestión Documentos",
    subLinks: [
      { name: "Subir nuevo contrato", url: "/user/new-contract" },
      { name: "Generar nuevo resumen", url: "/user/create-summary" },
      { name: "Subir documentos legales", url: "/user/legal-document" },
    ],
  },
  {
    name: "Tu área personal",
    subLinks: [
      { name: "Dashboard", url: "/user" },
      { name: "Tus documentos", url: "/user/documents" },
      { name: "Calendario de vencimientos", url: "/user/expiration-calendar" },
    ],
  },
];

export default function Sidebar() {
  const location = useLocation();
  const pathname = location.pathname;
  const router = useNavigate();
  const [showSidebar, setOpenSidebar] = useState(true);
  const [error, setError] = useState("");
  const toggleSidebar = () => setOpenSidebar((s) => !s);
  const largeScreen = useMediaQuery("(min-width: 992px)");
  useEffect(() => {
    if (!largeScreen) {
      setOpenSidebar(false);
    }
  }, [largeScreen]);
  const handleLogout = async () => {
    try {
      const res = await ApiRequests.logout();
      localStorage.setItem("access-token", JSON.stringify(""));
      router("/");
    } catch (error) {
      localStorage.setItem("access-token", JSON.stringify(""));
      router("/");
      handleError({ error, setError, router });
    }
  };
  return (
    <>
      <div
        className={`offcanvas offcanvas-start shadow border-0 ${
          largeScreen ? "show" : showSidebar ? "show" : ""
        }`}
        tabIndex={-1}
        id="sidebar"
        aria-labelledby="sidebarLabel"
      >
        <div className="offcanvas-header">
          <Link to={"/user"} className="offcanvas-title">
            <img alt="logo" src={logo} width={125} />
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex flex-column gap-3 h-100 justify-content-between">
            {sidebarLinks && (
              <>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {sidebarLinks.map((data, index) => (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`panelsStayOpen-heading${index}`}
                      >
                        <button
                          className="accordion-button fw-bold"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapse${index}`}
                          aria-expanded="true"
                          aria-controls={`panelsStayOpen-collapse${index}`}
                        >
                          {data.name}
                        </button>
                      </h2>
                      <div
                        id={`panelsStayOpen-collapse${index}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`panelsStayOpen-heading${index}`}
                      >
                        <div className="accordion-body">
                          <div className="list-group">
                            {data.subLinks.map((data, subIndex) => (
                              <Link
                                to={data.url}
                                key={subIndex}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                className={`list-group-item d-block list-group-item-action ${
                                  pathname == data.url ? "active" : ""
                                } `}
                                aria-current={pathname == data.url}
                              >
                                {data.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="list-group">
              <Link
                to={"/user/profile"}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className={`list-group-item d-flex align-items-center gap-2 fw-bold list-group-item-action ${
                  pathname == "profile" ? "active" : ""
                } `}
                aria-current={pathname == "profile"}
              >
                <FaUser size={20} />
                <p className="m-0">CTu perfil</p>
              </Link>
              <Link
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className={`d-flex align-items-center gap-2 fw-bold list-group-item list-group-item-action ${
                  pathname == "profile" ? "active" : ""
                } `}
                aria-current={pathname == "profile"}
                to={"/user/pricing"}
              >
                <IoMdPricetag size={20} />
                <p className="m-0">Precio</p>
              </Link>
              <button
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                className={`list-group-item list-group-item-action ${
                  pathname == "profile" ? "active" : ""
                } d-flex align-items-center gap-2 fw-bold pe-cursor`}
                onClick={handleLogout}
              >
                <MdLogout size={20} />
                <p className="m-0">Salir</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {!largeScreen && (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <Link className="navbar-brand" to={"/"}>
              <img alt="logo" src={logo} width={125} />
            </Link>
            <button
              className="navbar-toggler  shadow-none rounded-0  border-0"
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              data-bs-toggle="offcanvas"
              data-bs-target="#sidebar"
              aria-controls="sidebar"
              onClick={toggleSidebar}
            >
              {showSidebar === false ? (
                <FiMenu size={24} />
              ) : (
                <AiOutlineClose size={24} />
              )}
            </button>
          </div>
        </nav>
      )}
    </>
  );
}
