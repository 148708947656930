import { CustomButton } from "../../../../components/buttons/customButton";
import { PageLayout } from "../../../../components/layout/pageLayout";
import { handleError } from "../../../../helper";
import { ApiRequests } from "../../../../service/ApiRequests";
import styles from "../../../../styles/summary_layout.module.scss";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
const renderFile = (file: string | undefined, loading) => {
  if (loading) return <div>Loading...</div>;
  console.log("file", file);
  const url = file ? new URL(file) : null;
  switch (true) {
    case url?.href?.includes("pdf"):
      return (
        <object
          className="w-100"
          data={file}
          type="application/pdf"
          style={{
            // A1 Paper Ratio
            aspectRatio: "594/841",
          }}
        >
          <div>No PDF viewer available</div>
        </object>
      );
    case url?.href?.includes("docx"):
    case url?.href?.includes("doc"):
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            file
          )}&embedded=true`}
          width="100%"
          style={{
            // A1 Paper Ratio
            aspectRatio: "594/841",
          }}
        ></iframe>
      );
    default:
      return <div>Unsupported file format</div>;
  }
};
export default function LegalDocumentPage() {
  const params = useParams();
  console.log("paramsparamsparams", params);
  const [document, setDocument] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const router = useNavigate();

  useEffect(() => {
    console.log(params.id);
    getDocument(params.id);
  }, [params]);

  async function getDocument(id: string) {
    try {
      setLoading(true);
      const res = await ApiRequests.getDocument(id);
      setDocument(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError({ error, setError, router });
    }
  }
  const downloadFile = async () => {
    const filename = decodeURIComponent(
      document?.file.split("?")[0]?.split("/").pop()
    )
      .split("/")[1]
      .split(" ")
      .join("_");
    fetch(document.file, { method: "GET" })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, filename));
  };
  console.log("document", document);
  return (
    <PageLayout title="> Gestión Documentos / Sube  documentos legales">
      <div className="row g-5 align-items-center">
        <div className="col-lg-9">
          <section
            id={"printablediv"}
            className={`p-4 shadow rounded-3 bg-white ${styles.summary_layout_section}`}
          >
            {renderFile(document?.file, loading)}
          </section>
        </div>
        <div className="col-lg-3">
          <div className="row g-3">
            <div className="col-6 col-md-4 col-lg-12">
              <CustomButton
                onClick={() => downloadFile()}
                value="Imprmir"
                className="btn btn-primary w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
