import { FormFieldLayout } from "../form_field_layout";

import React, { FC } from "react";
import { Controller, Path } from "react-hook-form";

interface FieldProps {
  label?: string;
  fullWidth?: boolean;
  textArea?: boolean;
  control?: any;
  type?: string;
}
type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & FieldProps;

type TextAreaFieldProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FieldProps;
type CommonProps = InputFieldProps | TextAreaFieldProps;
function isTextArea(e: CommonProps): e is TextAreaFieldProps {
  return (e as TextAreaFieldProps).textArea === true;
}

export const Input_field_control: FC<InputFieldProps | TextAreaFieldProps> = ({
  label,
  fullWidth,
  textArea,
  ...props
}) => {
  if (isTextArea({ label, fullWidth, textArea, ...props })) {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <>
              <textarea
                {...field}
                // type={props.type}
                className="form-control border"
                id={props.id}
                placeholder={props.placeholder}
                required={props.required}
              />
              {fieldState.error?.message && (
                <small
                  id={`${props.name}-error`}
                  className="form-text text-danger"
                >
                  {fieldState.error?.message}
                </small>
              )}
            </>
          )}
        />
      </FormFieldLayout>
    );
  } else {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <>
              <input
                className="shadow form-control"
                {...field}
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                required={props.required}
              />
              {fieldState.error?.message && (
                <small
                  id={`${props.name}-error`}
                  className="form-text text-danger"
                >
                  {fieldState.error?.message}
                </small>
              )}
            </>
          )}
        />
      </FormFieldLayout>
    );
  }
};
