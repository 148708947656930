
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react"; // must go before plugins // a plugin!
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

export default function CalendarComponent({ events }: FullCalendar["props"]) {
  return (
    <FullCalendar
      initialView="dayGridMonth"
      plugins={[dayGridPlugin, interactionPlugin]}
      events={events}
      dayMaxEvents={1}
      eventMaxStack={1}
    />
  );
}
