import React, { useState, ChangeEventHandler, useEffect } from "react";
import { CustomButton } from "../../../components/buttons/customButton";
import { FloatingInputField } from "../../../components/form/floating_input_filed";
import { FloatingSelectField } from "../../../components/form/floating_select_filed";
import { PageLayout } from "../../../components/layout/pageLayout";
import { countries } from "../../../api/countries";
import { UploadFile } from "../../../components/form/upload_file";

import dummyImg from "../../../assets/images/avatar.png";
import { ApiRequests } from "../../../service/ApiRequests";
import { handleError } from "../../../helper";
import { useNavigate } from "react-router-dom";
import { assetBaseUrl } from "../../../util-constants";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserProfile } from "../../../types/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../config/firebase";

export default function ProfilePage() {
  const [profileImg, setProfileImg] = useState<string | null>();
  const [profile, setProfile] = useState<any>();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [errorProfile, setErrorProfile] = useState("");
  const [errorVerify, setErrorVerify] = useState("");
  const [errorProfileUpdate, setErrorProfileUpdate] = useState("");
  const router = useNavigate();
  async function sendVerificationEmail() {
    try {
      setErrorVerify("");
      setLoadingVerify(true);
      const res = await ApiRequests.sendVerificationEmail();
      console.log("res", res);
      setLoadingVerify(false);
      return res.data;
    } catch (error) {
      setLoadingVerify(false);
      handleError({ error, setError: setErrorVerify, router });
    }
  }
  async function getUserData() {
    try {
      setErrorProfile("");
      setLoadingProfile(true);
      const res = await ApiRequests.authenticate();
      setProfile(res?.data?.user);
      reset({
        name: res?.data?.user?.name,
        email: res?.data?.user?.email,
        address: res?.data?.user?.address,
        country: res?.data?.user?.country,
        date: res?.data?.user?.date,
        company: {
          name: res?.data?.user?.company?.name ?? "",
          address: res?.data?.user?.company?.address ?? "",
          cif: res?.data?.user?.company?.cif ?? "",
        }
      });
      if (res?.data?.user?.avatar) setProfileImg(res?.data?.user.avatar);
      console.log("profile", res?.data?.user);

      setLoadingProfile(false);
      return res.data;
    } catch (error) {
      setLoadingProfile(false);
      handleError({ error, setError: setErrorProfile, router });
    }
  }
  async function uploadAvatar(avatar: any) {
    try {
      if (!avatar) throw new Error("Please select an image");
      const imageUrl = await ApiRequests.uploadAvatarToFirebase(avatar);
      setErrorProfile("");
      setLoadingProfile(true);
      const res = await ApiRequests.updateProfile(
        {
          avatar: imageUrl,
        },
        profile.id
      );
      setProfile(res?.data?.user);
      reset({
        name: res?.data?.user?.name,
        email: res?.data?.user?.email,
        address: res?.data?.user?.address,
        country: res?.data?.user?.country,
        date: res?.data?.user?.date,
        company: {
          name: res?.data?.user?.company?.name ?? "",
          address: res?.data?.user?.company?.address ?? "",
          cif: res?.data?.user?.company?.cif ?? "",
        }

      });
      if (res?.data?.user?.avatar) setProfileImg(res?.data?.user.avatar);
      console.log("profileeee", res?.data?.user);

      setLoadingProfile(false);
      return res.data;
    } catch (error) {
      setLoadingProfile(false);
      handleError({ error, setError: setErrorProfile, router });
    }
  }
  useEffect(() => {
    getUserData();
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setProfileImg(e.target?.result as string);
        uploadAvatar(event.target.files?.[0]);
      });
      reader.readAsDataURL(file);
    }
  };
  const { handleSubmit, register, reset, control, getValues } =
    useForm<UserProfile>({
      defaultValues: {
        ...(profile
          ? {
            name: profile?.name,
            email: profile?.email,
            address: profile?.address,
            country: profile?.country,
            date: profile?.date,
            company: {
              name: profile?.company?.name ?? "",
              address: profile?.company?.address ?? "",
              cif: profile?.company?.cif ?? "",
            }
          }
          : {
            name: "",
            email: "",
            address: "",
            country: "",
            date: new Date(),
            company: {
              name: "",
              address: "",
              cif: "",
            }
          }),
      },
    });
  const submit: SubmitHandler<UserProfile> = async (values) => {
    try {
      console.log("values", values);
      const res = await ApiRequests.updateProfile(
        { ...values, email: undefined },
        profile?.id
      );
      console.log("updateProfile", res?.data);
    } catch (error) {
      handleError({ error, setError: setErrorProfileUpdate, router });
    }
  };
  return (
    <PageLayout title="S> Tu área personal / Profile">
      <form className="row g-4" onSubmit={handleSubmit(submit)}>
        <div>
          <div className="d-flex align-items-center gap-4 border rounded-4 p-4">
            <div>
              {profileImg ? (
                <img
                  src={profileImg}
                  alt={"profile Img"}
                  className="rounded-circle border"
                  width={125}
                  height={125}
                />
              ) : (
                <div
                  style={{
                    width: 125,
                    height: 125,
                  }}
                  className="rounded-circle border d-flex align-items-center justify-content-center"
                >
                  <div className="text-center">
                    <div className="fs-1 fw-bold">?</div>
                    <div className="fs-6">No Image</div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap gap-4">
              <UploadFile
                showIconsOnly={true}
                label={<div className="btn btn-primary">Sube tu foto</div>}
                id={"profileImg"}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="text"
            id="name"
            // defaultValue={getValues('name') ?? ""}
            label={"Nombre y apellidos"}
            required
            name="name"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FloatingSelectField
            id="country"
            label={"País"}
            defaultValue={profile?.country ?? ""}
            required
            control={control}
            options={countries.map((data, index) => (
              <option value={data.text} key={index}>
                {data.text}
              </option>
            ))}
          />
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="email"
            id="email"
            defaultValue={profile?.email ?? ""}
            label={"Email"}
            required
            name="email"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="text"
            id="address"
            label={"Dirección completa incluyendo tu ciudad"}
            required
            defaultValue={profile?.address ?? ""}
            name="address"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="date"
            id="date"
            label={"Fecha de Nacimiento"}
            required
            defaultValue={profile?.date ?? ""}
            name="date"
            control={control}
          />
        </div>
        <h1 className="text-left main-heading">
          Añadir una empresa
        </h1>
        <div className="col-md-6">
          <FloatingInputField
            type="text"
            id="company.name"
            label={"Nombre de la empresa"}
            required
            defaultValue={profile?.company?.name ?? ""}
            name="company.name"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="text"
            id="company.address"
            label={"Dirección completa"}
            required
            defaultValue={profile?.company?.address ?? ""}
            name="company.address"
            control={control}
          />
        </div>
        <div className="col-md-6">
          <FloatingInputField
            type="text"
            id="company.address"
            label={"CIF"}
            required
            defaultValue={profile?.company?.cif ?? ""}
            name="company.cif"
            control={control}
          />
        </div>
        {profile?.isEmailVerified == false && (
          <div className="col-md-6">
            <CustomButton
              onClick={sendVerificationEmail}
              className="btn btn-primary px-5 me-3"
              value="Verify Email"
              type="button"
            />
          </div>
        )}
        <div>
          <CustomButton
            className="btn btn-primary px-5 me-3"
            value="Guardar"
            type="submit"
          />
          <CustomButton
            className="btn btn-outline-dark px-5"
            value="Cancelar"
            type="reset"
          />
        </div>
      </form>
    </PageLayout>
  );
}
