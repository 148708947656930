const resetPassword = {
  title: "Are you sure?",
  message: "Are you sure you want to reset your password?",
};

const generateContract = {
  title: "Documento Legal Generado por IA: Confirme y Proceda",
  message:
    "Este documento se ha creado asistido por inteligencia artificial, le recomendamos revisarlo antes de proceder a su firma.",
};

const constants = {
  resetPassword,
  generateContract,
};

export default constants;
