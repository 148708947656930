import { CustomButton } from "../../../components/buttons/customButton";
import { CheckboxField } from "../../../components/form/checkbox_field";
import { DatePickerField } from "../../../components/form/date_picker";
import { InputField } from "../../../components/form/input_field";
import { Input_field_control } from "../../../components/form/input_field_control";
import { SelectField } from "../../../components/form/select_field";
import { UploadFile } from "../../../components/form/upload_file";
import { PageLayout } from "../../../components/layout/pageLayout";
import { TransparentShadowSection } from "../../../components/transparent-shadow";
import { handleError } from "../../../helper";
import { ApiRequests } from "../../../service/ApiRequests";
import { CreateLegalDocument } from "../../../types/auth";
import { useNavigate } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../config/firebase";
import { contractTypes } from "../../../util-constants";
import { toast } from "react-hot-toast";

const LegalSelectField = SelectField<CreateLegalDocument>;

export default function LegalDocumentPage() {
  const [file, setFile] = useState<File>();
  const [error, setError] = useState("");
  const [loading, setLoadng] = useState(false);

  const { handleSubmit, control } = useForm<CreateLegalDocument | any>({
    defaultValues: {
      lastDate: false,
      type: contractTypes[0].value,
      name: "",
      signatureDate: new Date(),
      expirationDate: new Date(),
    },
  });

  const router = useNavigate();
  const submit: SubmitHandler<CreateLegalDocument> = async (e) => {
    try {
      if (file) {
        setLoadng(true);
        if (!file) {
          toast.error("Debes subir un archivo");
          return;
        }
        toast.loading("Subiendo archivo", { id: "uploading" });
        const mountainsRef = ref(
          storage,
          `legal-document/${Math.random().toString(36).substring(2, 5)}-${
            file.name
          }`
        );
        await uploadBytes(mountainsRef, file);
        const imageUrl = await getDownloadURL(mountainsRef);
        toast.success("Archivo subido", { id: "uploading" });
        const res = await ApiRequests.createDocuments({
          ...e,
          file: imageUrl,
        });
        setLoadng(false);
        console.log("token", res.data);
      } else {
        toast.error("Debes subir un archivo");
      }
    } catch (error) {
      setLoadng(false);

      handleError({ error, setError, router });
    }
  };
  return (
    <PageLayout title="> Gestión Documentos / Sube  documentos legales">
      <TransparentShadowSection>
        Puedes subir tus propios contratos ya firmados o documentos legales para
        tenerlos todos en un mismo espacio, tambien podemos gestionar su
        vencimiento.
      </TransparentShadowSection>
      <form className="mt-5 row g-5 g-md-4" onSubmit={handleSubmit(submit)}>
        <div className="text-center col-12">
          <UploadFile
            handleFile={(file) => {
              setFile(file);
            }}
          />
        </div>
        <div className="col-12">
          <LegalSelectField
            label={"Elige tipo contrato"}
            options={contractTypes}
            name="type"
            control={control}
          />
        </div>

        <div className="col-12">
          <Input_field_control
            type="text"
            id="name"
            required
            label={"Nombre del documento"}
            placeholder="Nombre"
            name="name"
            control={control}
          />
        </div>
        <div className="col-12">
          <CheckboxField
            type="text"
            id="nombre"
            placeholder="Nombre"
            control={control}
            name="lastDate"
            label={"¿Incluir en vencimientos?"}
            paragraph="STe avisaremos antes del vencimiento si lo registras."
          />
        </div>

        <div className="col-12">
          {/* <LegalSelectField label={"Vencimiento"} options={expiration} /> */}
          <DatePickerField
            type="text"
            id="expirationDate"
            placeholder="Nombre"
            control={control}
            label={"Vencimiento"}
            name="expirationDate"
          />
        </div>
        <div className="col-12">
          {/* <LegalSelectField label={"Vencimiento"} options={expiration} /> */}
          <DatePickerField
            type="text"
            id="expirationDate"
            placeholder="Nombre"
            control={control}
            label={"fecha de firma"}
            name="signatureDate"
          />
        </div>
        <div className="col-12 text-center">
          <CustomButton
            // onClick={createSummary}
            className="btn btn-sm btn-dark rounded-pill"
            type="submit"
            value={loading ? "carregando documento..." : "Subir documento"}
          />
        </div>
      </form>
    </PageLayout>
  );
}
