import Sidebar from "components/layout/sidebar";
import { Outlet } from "react-router-dom";

export default function PageOutlet() {
  // const auth = useSelector((state:RootState) => state.auth);
  // let location = useLocation();
  // console.log("auth.user",auth.user)
  // if (!auth.user) {
  //   return <Navigate to="/login" state={{ from: location }} replace />
  // }
  return (
    <>
      <Sidebar />
      <div className="margin-left-300">
        <section className="container py-5 px-lg-5">
          <Outlet />
        </section>
      </div>
    </>
  );
}
