import { CustomButton } from "../../../components/buttons/customButton";
// import { roboto } from "../../../utils/fonts";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ApiRequests } from "../../../service/ApiRequests";
import styles from "../../../styles/price.module.scss";
const businessPackages = [
  {
    package: "Gratis",
    price: 0,
    description: "Para personas con pocas necesidades legales",
    popular: false,
    type: "monthly",
    name: "basic",
  },

  {
    package: "Pro",
    price: 19,
    description: "Para personas y negocios con algo mas de movimiento",
    popular: true,
    type: "monthly",
    name: "pro",
  },
  {
    package: "Empresas",
    price: 99,
    description: "Para empresas con gran volumen de contratos",
    popular: false,
    type: "monthly",
    name: "enterprise",
  },
];

const clsx = (...classes: string[]) => classes.filter(Boolean).join(" ");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
export default function Page() {
  return (
    <Elements stripe={stripePromise}>
      <Price />
    </Elements>
  );
}

const Price = () => {
  const stripe = useStripe();

  return (
    <div className="text-center">
      <p className="mb-5">
        Elige la suscripción que mas se ajuste a tus necesidades.
      </p>
      {businessPackages && (
        <div className="row align-items-center justify-content-center g-4 g-lg-0 ">
          {businessPackages.map((data, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <div
                className={`text-start p-4 bg-white rounded-4 ${
                  data.popular && styles.popularPackageCard
                } `}
              >
                <div className="text-end">
                  <CustomButton
                    className={
                      "rounded-pill px-3 border-2 p-1 btn-outline-primary"
                    }
                    style={{
                      fontSize: "0.8rem",
                      visibility: data.popular ? "visible" : "hidden",
                    }}
                    value="Más Popular"
                  />
                </div>
                <h6 style={{ fontSize: "1.5rem" }} className="fw-semibold mb-3">
                  {data.package}
                </h6>
                <div className="position-relative">
                  <h5 className="position-absolute top-0 fw-semibold">€</h5>
                  <h1
                    style={{
                      fontSize: "2.5rem",
                    }}
                    className="d-inline-block ms-4 fw-bolder"
                  >
                    {data.price}
                  </h1>
                  <span>/mes</span>
                </div>
                <p className="mb-5 mt-3 col-10">{data.description}</p>
                <CustomButton
                  value="Empieza tu plan"
                  className={`w-100 ${data.popular && " mb-4"} btn-${
                    data.popular ? "primary" : "outline-dark"
                  }`}
                  onClick={async () => {
                    if (data.name === "basic") return;
                    const res = await ApiRequests.createCheckoutSession({
                      plan: data.name,
                      successUrl: `${window.location.origin}/user`,
                      failureUrl: `${window.location.origin}/user`,
                    });
                    window.location.href = res.data.url;
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
