import { CustomButton } from "components/buttons/customButton";
import React, { useEffect, useId, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Path, UseFormRegister, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { convertTextToImage } from "./functions";
import { register } from "constants/loading";
import { ApiRequests } from "service/ApiRequests";
import { useNavigate, useParams } from "react-router-dom";
const fonts = [
  "Dancing Script",
  "Pacifico",
  "Pinyon Script",
  "Italianno",
  "Indie Flower",
  "Satisfy",
  "Cookie",
  "Alex Brush",
  "Tangerine",
  "Courgette",
  "Great Vibes",
  "Yellowtail",
  "Kaushan Script",
  "Sacramento",
  "Luxurious Script",
] as const;
interface DefaultValues {
  font: (typeof fonts)[number];
  name: string;
}
export default function FontsPopup({ text }: { text: string }) {
  const { id } = useParams();
  const [show, setShow] = useState<false | "input" | "share">(false);
  const { register, getValues } = useForm<DefaultValues>({
    defaultValues: {
      font: "Italianno",
      name: "",
    },
  });
  const navigate = useNavigate();
  return (
    <>
      {/* @ts-ignore */}
      <SweetAlert
        title="Firmar y compartir"
        confirmBtnText="Entregar"
        cancelBtnText="Cerca"
        showCancel
        style={{
          width: "50rem",
        }}
        onConfirm={async () => {
          toast.loading("enlace generador...", { id: "generatingLink" });
          const name = getValues("name");
          const font = getValues("font");
          const { default: jsPDF, AcroFormTextField } = await import("jspdf");
          const doc = new jsPDF({
            orientation: "p",
          });
          const fontSize = 12; // Set font size to 12
          const lineHeight = 7; // Set line height to 15

          doc.setFontSize(fontSize);
          const newText = text.split("\n");
          const heading = newText.shift();
          // Split text into array of lines
          const lines = doc.splitTextToSize(
            newText.join("\n"),
            doc.internal.pageSize.width - 20
          );
          // Set initial y position
          let y = 20;
          doc.setFontSize(20);
          const headingLines = doc.splitTextToSize(
            heading,
            doc.internal.pageSize.width - 20
          );
          for (let i = 0; i < headingLines.length; i++) {
            if (y > doc.internal.pageSize.height - 20) {
              // Add new page if current page is full
              doc.addPage();
              y = 20;
            }
            doc.setFontSize(20);
            doc.setFont(undefined, "bold");
            doc.text(headingLines[i], 10, y);
            y += lineHeight; // Increase y position by line height
          }
          doc.setFontSize(fontSize);
          // Loop through lines and add to PDF
          let fieldCount = 0;
          for (let i = 0; i < lines.length; i++) {
            if (y > doc.internal.pageSize.height - 20) {
              // Add new page if current page is full
              doc.addPage();
              y = 20;
            }
            doc.setFontSize(fontSize);
            doc.setFont(undefined, "normal");
            if (lines[i].trim()[0] === "_" && lines[i].trim().at(-1) === "_") {
              fieldCount++;
              if (fieldCount === 1) {
                console.log("font-family", font);
                const text = await convertTextToImage(name, 20, font);
                doc.addImage({
                  imageData: text,
                  format: "PNG",
                  x: 10,
                  y: y - 10,
                  width: 50 * 1.5,
                  height: 12.5 * 1.5,
                  compression: "SLOW",
                });
              } else {
                const field = new AcroFormTextField();
                field.x = 10;
                field.y = y - 5;
                field.width = 100;
                field.height = lineHeight;
                field.fontSize = fontSize;
                field.fieldName = "buyer";
                doc.addField(field);
              }
            } else {
              doc.text(lines[i], 10, y);
            }
            y += lineHeight; // Increase y position by line height
          }
          const blob = doc.output("blob");
          await ApiRequests.uploadPDF(blob, id);
          setShow("share");
          toast.success("enlace generado", { id: "generatingLink" });
        }}
        onCancel={() => {
          setShow(false);
        }}
        show={show === "input"}
      >
        {() => (
          <form>
            <div className="form-group">
              <div>
                <label
                  htmlFor="name"
                  style={{
                    fontSize: "0.8rem",
                  }}
                  className="text-start w-100"
                >
                  Ingrese su nombre:
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control border px-3 py-2"
                  placeholder="Ingrese su nombre"
                  {...register("name")}
                />
              </div>
            </div>
            <div
              style={{
                height: "50vh",
                overflowY: "auto",
              }}
              className="row"
            >
              {fonts.map((font) => (
                <div className="col-lg-4 col-6 py-2">
                  <RadioButtonBox<DefaultValues>
                    key={font}
                    value={font}
                    register={register}
                    name="font"
                  >
                    {font}
                  </RadioButtonBox>
                </div>
              ))}
            </div>
          </form>
        )}
      </SweetAlert>
      {/* @ts-ignore */}
      <SweetAlert
        // title= Share the link in spanish
        title="Comparte el enlace"
        // Copy in spanish
        confirmBtnText="Copiar"
        // Close in spanish
        cancelBtnText="Cerca"
        showCancel
        style={{
          width: "40rem",
        }}
        onConfirm={() => {
          navigator.clipboard.writeText(
            `${window.location.origin}/share/contract/${id}`
          );
          toast.success("enlace copiado");
        }}
        onCancel={() => {
          setShow(false);
        }}
        show={show === "share"}
      >
        {() => (
          <div className="form-group">
            <div>
              <label
                htmlFor="name"
                style={{
                  fontSize: "0.8rem",
                }}
                className="text-start w-100"
              >
                {/* Link in spanish */}
                Enlace
              </label>
              <input
                type="text"
                id="link"
                className="form-control border px-3 py-2"
                placeholder="Ingrese su nombre"
                value={`${window.location.origin}/share/contract/${id}`}
                readOnly
              />
            </div>
          </div>
        )}
      </SweetAlert>
      <div className="col-6 col-md-4 col-lg-12">
        <CustomButton
          onClick={() => setShow("input")}
          value="Firmar y compartir"
          className="btn btn-primary w-100"
        />
      </div>
    </>
  );
}

// Highlight the Box when the radio button is selected
const RadioButtonBox = <T extends {}>({
  children,
  register,
  name,
  ...radioInputProps
}: React.ComponentProps<"input"> & {
  children: string;
  register: UseFormRegister<T>;
  name: Path<T>;
}) => {
  const id = useId();
  const [image, setImage] = useState<HTMLCanvasElement>();
  useEffect(() => {
    convertTextToImage(children, 20, children).then((image) => {
      setImage(image);
    });
  }, [children]);
  return (
    <label
      className="radio-box d-flex align-items-center border py-3 px-2 rounded-3"
      style={{
        aspectRatio: "1/0.4",
        cursor: "pointer",
      }}
      htmlFor={radioInputProps.id || id}
    >
      <input
        hidden
        id={id}
        type="radio"
        {...radioInputProps}
        {...register(name)}
      />
      <div className="w-100 ps-3 text-start">
        {image ? (
          <img
            src={image.toDataURL()}
            alt={children}
            style={{
              aspectRatio: image.width / image.height,
              width: "100%",
            }}
          />
        ) : (
          children
        )}
      </div>
    </label>
  );
};
