import { PageLayout } from "../../../components/layout/pageLayout";
import CalendarComponent from "../../../components/calendar/Calendar";
import { ApiRequests } from "../../../service/ApiRequests";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../helper";
import { initialValue } from "../../../util-constants";
import { CalendarOptions } from "@fullcalendar/core";

export default function ExpirationCalendarPage() {
  const router = useNavigate();
  const [documents, setDocuments] = useState(initialValue);
  const [contracts, setContracts] = useState(initialValue);
  const [errorDocuments, setErrorDocuments] = useState("");
  const [errorContracts, setErrorContracts] = useState("");
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingContracts, setLoadingContracts] = useState(false);
  async function getDocuments() {
    try {
      setErrorDocuments("");
      setLoadingDocuments(true);
      const res = await ApiRequests.getDocuments({ limit: 1000 });
      setDocuments({
        ...res?.data,
        results: res?.data?.results.map((doc) => ({
          ...doc,
          type: "legal-document",
        })),
      });
      setLoadingDocuments(false);
      // return res.data;
    } catch (error) {
      setLoadingDocuments(false);
      handleError({ error, setError: setErrorDocuments, router });
    }
  }
  async function getContracts() {
    try {
      setLoadingContracts(true);
      const res = await ApiRequests.getContracts({});
      setContracts({
        ...res?.data,
        results: res?.data?.results.map((doc) => ({
          ...doc,
          type: "contract",
        })),
      });
      setLoadingContracts(false);
      // return res.data;
    } catch (error) {
      setLoadingContracts(false);
      handleError({ error, setError: setErrorContracts, router });
      // setErrorDocument(error?.response?.data?.message ?? "Something went wrong")
    }
  }
  useEffect(() => {
    getDocuments();
    getContracts();
  }, []);
  const calendarOptions: CalendarOptions["events"] = useMemo(
    () =>
      [...documents.results, ...contracts.results]
        .filter((el) => el.expirationDate || el.expiration_date)
        .map((doc) => ({
          editable: false,
          url: `/user/${doc.type}/${doc.id}`,
          className: `absolute item-calendar ${doc.type}`,
          date: doc.expirationDate || doc.expiration_date,
          color: "#fff0",
          allDay: true,
        })),
    [documents, contracts]
  );
  return (
    <PageLayout title="> Tu área personal / Calendario de vencimientos">
      <p className="text-center">
        Pulsa sobre los iconos para ver mas detalles
      </p>
      <div className="p-3 bg-white">
        <CalendarComponent events={calendarOptions} />
        {/*  */}
        {errorDocuments}
      </div>
    </PageLayout>
  );
}
