import moment from "moment";
import constractImg from "../../assets/images/icons/contract.png";

import { FC } from "react";
type PdfCardProps = {
  date: string;
};
export const PdfCard: FC<PdfCardProps> = ({ date }) => {
  return (
    <div className="shadow text-center p-2 rounded-4">
      <h6
        style={{
          textTransform: "uppercase",
        }}
      >
        {moment(date).format(
          // DD MMMM
          "MMMM, DD"
        )}
      </h6>
      <img src={constractImg} alt="Icon" height={40} />
    </div>
  );
};
