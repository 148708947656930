import type { FC } from "react";
type TransparentShadowSectionProps = React.HTMLAttributes<HTMLDivElement>;
export const TransparentShadowSection: FC<TransparentShadowSectionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={`shadow p-2 d-inline-block ${className}`} {...props}>
      <p className="m-0">{children}</p>
    </div>
  );
};
