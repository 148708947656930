import React, { FC } from "react";
interface FormFieldLayoutProps {
  id?: string;
  label?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
}
export const FormFieldLayout: FC<FormFieldLayoutProps> = ({
  id,
  label,
  fullWidth,
  children,
}) => {
  return (
    <div className="form-group row g-2 g-md-4">
      {label && (
        <div className="col-md-4 col-lg-3">
          <label
            htmlFor={id}
            className={"shadow p-2 bg-white rounded-3 form-label"}
          >
            {label}
          </label>
        </div>
      )}
      <div className={`${label ? "col-md-8 col-lg-9" : ""}`}>
        <div className={`${fullWidth ? "d-block" : "d-md-inline-block"} `}>
          {children}
        </div>
      </div>
    </div>
  );
};
