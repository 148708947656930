import { router } from "App";
import axios, { AxiosError } from "axios";
import { servicePaths } from "util-constants";

const version = "/v1/";
export const baseURL =
  (process.env.REACT_APP_BASE_URL ?? "http://localhost:3001") + version;

export const safeJSONParse = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};
const apiService = axios.create({
  baseURL,
  timeout: 300000,
});
apiService.interceptors.request.use((config) => {
  var token = localStorage.getItem("access-token");
  if (token) {
    config.headers.set("Authorization", `Bearer ${safeJSONParse(token)}`);
  }
  config.headers.set("Access-Control-Allow-Origin", "*");
  config.headers.set("access-control-allow-headers", "*");
  config.headers.set("access-control-allow-methods", "*");
  config.headers.set("access-control-allow-origin", "*");
  config.headers.set("Accept", "application/json, text/plain, */*");
  return config;
});

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    try {
      const originalRequest = error?.config;
      if (!(error instanceof AxiosError)) throw error;
      if (error?.response?.data?.code !== 401) throw error;
      if (error?.response?.status !== 401) throw error;
      if (
        error?.request?.responseURL?.includes("auth") &&
        !error?.request?.responseURL?.includes(servicePaths.authenticate)
      )
        throw error;
      if (originalRequest?._retry) throw error;
      originalRequest._retry = true;
      return await axios
        .post(
          `${baseURL}${servicePaths.refreshTokens}`,
          {},
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(async ({ data }) => {
          const token = data?.access?.token ?? "";
          if (token) {
            localStorage.setItem("access-token", JSON.stringify(token));
            originalRequest.headers.set("Authorization", `Bearer ${token}`);
            const res = await axios(originalRequest);
            if (window.location.pathname === "/") router.navigate("/user");
            return res;
          }
        })
        .catch(async () => {
          try {
            await axios.post(
              `${baseURL}${servicePaths.logout}`,
              {},
              { withCredentials: true }
            );
          } catch (error) {}
          router.navigate("/");
          localStorage.removeItem("access-token");
          return Promise.reject(error);
        });
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default apiService;
