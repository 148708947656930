// export const baseURL = "http://localhost:3000/api/"
const version = "v1/";
export const baseURL =
  (process.env.REACT_APP_BASE_URL ?? "http://localhost:3001/") + version;
export const assetBaseUrl =
  process.env.REACT_APP_BASE_URL ?? "http://localhost:3001/";
export const servicePaths = {
  // auth
  login: "auth/login",
  logout: "auth/logout",
  register: "auth/register",
  forgotPassword: "auth/forgot-password",
  resetPassword: "auth/reset-password",
  authenticate: "auth/authenticate",
  sendVerificationEmail: "auth/send-verification-email",
  verifyEmail: "auth/verify-email",
  refreshTokens: "auth/refresh-tokens",
  users: "users",
  avatar: "users/avatar",
  generate: "generate",
  documents: "documents",
  summaries: "summaries",
  contracts: "contracts",
  payment: "payment",
  subscriptions: "subscriptions",
  checkout: "payment/checkout",
  monthlyDocumentsCount: "documents/monthly/count",
  monthlyContractsCount: "contracts/monthly/count",
  monthlySummariesCount: "summaries/monthly/count",
  counts: "subscriptions/user",
};

export const contractTypes = [
  {
    name: "Contrato de compraventa",
    value: "Contrato de compraventa",
  },
  {
    name: "Contrato de arrendamiento",
    value: "Contrato de arrendamiento",
  },
  {
    name: "Contrato de trabajo",
    value: "Contrato de trabajo",
  },
  {
    name: "Contrato de préstamo",
    value: "Contrato de préstamo",
  },
  {
    name: "Contrato de confidencialidad",
    value: "Contrato de confidencialidad",
  },
  {
    name: "Contrato de colaboración",
    value: "Contrato de colaboración",
  },
  {
    name: "Contrato de fianza",
    value: "Contrato de fianza",
  },
  {
    name: "Contrato de franquicia",
    value: "Contrato de franquicia",
  },
  {
    name: "Contrato de compraventa con reserva de dominio",
    value: "Contrato de compraventa con reserva de dominio",
  },
  {
    name: "Contrato de prestación de servicios",
    value: "Contrato de prestación de servicios",
  },
];

export const initialValue = {
  limit: 10,
  page: 1,
  results: [],
  totalPages: 1,
  totalResults: 0,
};
