import React, { FC, useState } from "react";
import "react-input-range/lib/css/index.css";
import InputRange, { Range, InputRangeProps } from "react-input-range";

export const InputRangeSlider: FC<Partial<InputRangeProps>> = (props) => {
  return (
    <>
      <InputRange
        {...props}
        value={props.value}
        minValue={0}
        // disabled
        maxValue={props.maxValue === Infinity ? 9999 : props.maxValue ?? 10}
        onChange={() => {}}
      />
      <h1 className="text-primary my-3">
        {props.value as number}/
        {props.maxValue === Infinity ? "∞" : props.maxValue}
      </h1>
    </>
  );
};
