import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";

export const handleError = ({
  error,
  setError,
  router,
}: {
  error: AxiosError<any, any>;
  setError: Dispatch<SetStateAction<string>>;
  router: NavigateFunction;
}) => {
  if (error instanceof Error) {
    console.log("handleError:", error);
    setError(error?.response?.data?.message ?? error.message ?? "Something went wrong");
  } else if (typeof error === "string") {
    setError(error);
  } else {
    setError("Something went wrong");
  }
};
