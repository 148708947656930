import { CustomButton } from "../../components/buttons/customButton";
import { FloatingInputField } from "../../components/form/floating_input_filed";
import AuthLayout from "../../components/layout/auth-layout";
import { handleError } from "../../helper";
import { ApiRequests } from "../../service/ApiRequests";
import { RegisterCredentials } from "../../types/auth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
const RegisterField = FloatingInputField<RegisterCredentials>;
const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));
export default function RegisterPage() {
  const [error, setError] = useState("");
  const [loading, setLoadng] = useState(false);

  const router = useNavigate();
  const { handleSubmit, control } = useForm<RegisterCredentials>();
  const submit: SubmitHandler<RegisterCredentials> = async (values) => {
    try {
      setLoadng(true);
      const res = await ApiRequests.register({
        name: values.name,
        email: values.email,
        password: values.password,
      });
      localStorage.setItem("access-token", res.data.access.token);
      setLoadng(false);
      await wait(100);
      router("/login");
    } catch (error) {
      setLoadng(false);
      handleError({ error, setError, router });
    }
  };
  return (
    <>
      <div className="mb-4">
        <h4>Request Early Access</h4>
        <p className="text-secondary">
          Subscribe to be eligible for early access membership
        </p>
      </div>
      <form className="row g-4" onSubmit={handleSubmit(submit)}>
        <div>
          <RegisterField
            control={control}
            type="text"
            id="name"
            label={"Name"}
            required
            name="name"
          />
        </div>
        <div>
          <RegisterField
            type="email"
            id="email"
            label={"Email"}
            required
            control={control}
            name="email"
          />
        </div>
        <div>
          <RegisterField
            type="password"
            id="password"
            label={"Password"}
            required
            control={control}
            name="password"
          />
        </div>
        <div>
          <RegisterField
            type="password"
            id="confirmPassword"
            label={"Confirm Password"}
            required
            name="confirmPassword"
            control={control}
          />
        </div>
        <div>
          {error && <p>{error}</p>}
          <CustomButton
            type="submit"
            value={loading ? "Registering" : "Register"}
            className="w-100 btn btn-dark py-3"
          />
        </div>
        <p className="text-center">
          {`Already  have an account ? `}
          <Link to={"/"} className="text-primary">
            Login Account
          </Link>
        </p>
      </form>
    </>
  );
}
