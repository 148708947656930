
import { ChangeEventHandler, FC, useState } from "react";
import { IoMdCloudUpload } from "react-icons/io";
import { ReactNode } from "react";
interface UploadFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  showIconsOnly?: boolean;
  handleFile?: (file: File) => void
}

const noselectedFile = "Select Doc";
export const UploadFile: FC<UploadFileProps> = ({
  id,
  label,
  showIconsOnly,
  handleFile,
  ...props
}) => {
  const [fileName, setFileName] = useState(noselectedFile);
  const getFileName: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target?.files && e.target?.files?.length > 0) {
      setFileName(e.target.files[0].name);
      if (handleFile) handleFile(e.target.files[0])
    } else {
      setFileName(noselectedFile);
    }
  };

  return (
    <div className="d-inline-flex gap-4 align-items-center ">
      <label className="form-label d-inline-block" htmlFor={id || "uploadFile"}>
        {label || (
          <IoMdCloudUpload size={52} className="text-primary pe-cursor" />
        )}
      </label>
      <input
        type="file"
        id={id || "uploadFile"}
        onChange={getFileName}
        className="d-none form-control"
        {...props}
      />
      {!showIconsOnly && (
        <p className="d-inline-block m-0 text-wrap text-start">{fileName}</p>
      )}
    </div>
  );
};
