import { FC } from "react";
import { Control, Controller, Path } from "react-hook-form";
import { FormFieldLayout } from "../form_field_layout";

interface SelectFieldProps<T>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  fullWidth?: boolean;
  control?: Control<T>;
  options: { name: string; value: string }[];
  name?: Path<T>;
}

export const SelectField = <T extends {} = {}>({
  label,
  fullWidth,
  options,
  ...props
}: SelectFieldProps<T>) => {
  return (
    <FormFieldLayout fullWidth={fullWidth} id={props.id} label={label}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState }) => (
          <select
            {...props}
            {...field}
            aria-label="select field"
            className="shadow form-select"
          >
            {options &&
              options.map((data, index) => (
                <option value={data.value} key={index}>
                  {data.name}
                </option>
              ))}
          </select>
        )}
      />
    </FormFieldLayout>
  );
};
