import Logo from "../../assets/images/logo/legaly.png";
import CircleLogo from "../../assets/images/logo/favicon.png";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
// import { useSelector } from "react-redux"
// import { Navigate, useLocation } from "react-router-dom"
// import { RootState } from "store";

const AuthLayout = () => {

  // const auth = useSelector((state:RootState) => state.auth);
  // let location = useLocation();
  // console.log("auth.user",auth.user)
  // if (!auth.user) {
  //   return <Navigate to="/login" state={{ from: location }} replace />
  // }

  return (
    <section className="d-flex min-vh-100 justify-content-center align-items-center my-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 text-center d-none d-lg-block">
            <img
              src={CircleLogo}
              alt="logo"
              height={200}
              width={200}
              className="mb-4"
            />
            <h1 className="text-center main-heading">
              Wellcome to <br /> Legaly
            </h1>
          </div>
          <div className="col-lg-6">
            <section className="bg-white p-4 rounded-4 ">
              <img src={Logo} alt="logo" width={100} className="mb-4" />
              <Outlet />
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
