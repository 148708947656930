// "Logging in...", "Logged in successfully"
export const login = {
  loading: "Logging in...",
  success: "Logged in successfully",
};

// "Sending email...", "Email sent successfully"
export const sendEmail = {
  loading: "Sending email...",
  success: "Email sent successfully",
};

// "Resetting password...", "Password reset successfully"
export const resetPassword = {
  loading: "Resetting password...",
  success: "Password reset successfully",
};

// "Logging out...", "Logged out successfully"
export const logout = {
  loading: "Logging out...",
  success: "Logged out successfully",
};

// "Registering...", "Registered successfully"
export const register = {
  loading: "Registering...",
  success: "Registered successfully",
};

// "sending Verification Email...", "Verification Email has been sent successfully. Check inbox!"
export const sendVerificationEmail = {
  loading: "sending Verification Email...",
  success: "Verification Email has been sent successfully. Check inbox!",
};

// "Verifying Email...", "Email Verification has been done successfully"
export const verifyEmail = {
  loading: "Verifying Email...",
  success: "Email Verification has been done successfully",
};

// "Generating contract...", "Contract generated successfully",
export const generateContract = {
  loading: "Generating contract...",
  success: "Contract generated successfully",
  estimatedTime: 100,
};

// "Updating profile...", "Profile updated successfully"
export const updateProfile = {
  loading: "Updating profile...",
  success: "Profile updated successfully",
};

// "Uploading Image...", "Image uploaded successfully"
export const uploadImage = {
  loading: "Uploading Image...",
  success: "Image uploaded successfully",
};

// "Creating document...", "Document created successfully"
export const createDocument = {
  loading: "Creating document...",
  success: "Document created successfully",
};

// "Fetching document...", "Document fetched successfully"
export const fetchDocument = {
  loading: "Fetching document...",
  success: "Document fetched successfully",
};

// "Fetching contract...", "Contract fetched successfully"
export const fetchContract = {
  loading: "Fetching contract...",
  success: "Contract fetched successfully",
};

// "Creating summary...", "Summary created successfully"

export const createSummary = {
  loading: "Creating summary...",
  success: "Summary created successfully",
  estimatedTime: 50,
};

// "Fetching summary...", "Summary fetched successfully"
export const fetchSummary = {
  loading: "Fetching summary...",
  success: "Summary fetched successfully",
};

// "Processing...", "Processed successfully"
export const createSession = {
  loading: "Processing...",
  success: "Processed successfully",
};

// "Uploading Summary...", "Summary uploaded successfully"
export const uploadSummary = {
  loading: "Uploading Summary...",
  success: "Summary uploaded successfully",
};

// "Uploading Avatar...", "Avatar uploaded successfully"
export const uploadAvatar = {
  loading: "Uploading Avatar...",
  success: "Avatar uploaded successfully",
};

const gettingPdf = {
  loading: "Getting PDF...",
  success: "PDF fetched successfully",
};

const uploadPDF = {
  loading: "Signing PDF...",
  success: "PDF signed successfully",
};

const constants = {
  login,
  sendEmail,
  resetPassword,
  logout,
  register,
  sendVerificationEmail,
  verifyEmail,
  generateContract,
  updateProfile,
  uploadImage,
  createDocument,
  fetchDocument,
  fetchContract,
  createSummary,
  fetchSummary,
  createSession,
  uploadSummary,
  uploadAvatar,
  gettingPdf,
  uploadPDF,
};

export default constants;
