import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiRequests } from "../../service/ApiRequests";

export default function VerifyEmail() {
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoadng] = useState(false);
  const verifyEmail = async () => {
    try {
      setLoadng(true);
      const res = await ApiRequests.verifyEmail({
        token: `${searchParams.get("token")}`,
      });
      setLoadng(false);
      router("/");
    } catch (error) {
      router("/");
      setLoadng(false);
      // handleError({ error, setError, router });
    }
  };
  useEffect(() => {
    if (searchParams.get("token")) {
      verifyEmail();
    } else {
      router("/");
    }
  }, []);
  return (
    <>
      <p>Verifying Email Address.</p>
      <form className="row g-4">
        <div>
          <div className="py-5">
            <div className="spinner-border m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
