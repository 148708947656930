import { Control, Controller, Path } from "react-hook-form";
import { FormFieldLayout } from "../form_field_layout";

import React from "react";

interface FieldProps {
  label?: string;
  fullWidth?: boolean;
  textArea?: boolean;
  control?: any;
}
type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & FieldProps;

type TextAreaFieldProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FieldProps;
type CommonProps = InputFieldProps | TextAreaFieldProps;
function isTextArea(e: CommonProps): e is TextAreaFieldProps {
  return (e as TextAreaFieldProps).textArea === true;
}

export const InputField = <T extends {}>({
  label,
  fullWidth,
  textArea,
  ...props
}: (InputFieldProps | TextAreaFieldProps) & {
  control: Control<T>;
  name: Path<T>;
}) => {
  if (isTextArea({ label, fullWidth, textArea, ...props })) {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <>
              <textarea
                {...(props as TextAreaFieldProps)}
                {...field}
                className="form-control border"
                id={props.id}
                placeholder={props.placeholder}
                required={props.required}
              />
              {fieldState.error?.message && (
                <small
                  id={`${props.name}-error`}
                  className="form-text text-danger"
                >
                  {fieldState.error?.message}
                </small>
              )}
            </>
          )}
        />
      </FormFieldLayout>
    );
  } else {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <>
              <input
                {...(props as InputFieldProps)}
                {...field}
                className="shadow form-control"
                id={props.id}
                placeholder={props.placeholder}
                required={props.required}
              />
              {fieldState.error?.message && (
                <small
                  id={`${props.name}-error`}
                  className="form-text text-danger"
                >
                  {fieldState.error?.message}
                </small>
              )}
            </>
          )}
        />
      </FormFieldLayout>
    );
  }
};
