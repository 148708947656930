import { FC, ReactNode } from "react";

export const CustomButton: FC<
  Omit<React.ComponentProps<"button">, "value"> & {
    value: ReactNode;
  }
> = ({ value, className = "btn-primary", ...props }) => {
  return (
    <button
      type="button"
      className={`btn ${className ? className : ""}`}
      {...props}
    >
      {value}
    </button>
  );
};
