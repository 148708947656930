export const countries = [
  {
    id: "af",
    text: "Afghanistan",
    callingid: "93",
  },
  {
    id: "ax",
    text: "Aland Islands",
    callingid: "358",
  },
  {
    id: "al",
    text: "Albania",
    callingid: "355",
  },
  {
    id: "dz",
    text: "Algeria",
    callingid: "213",
  },
  {
    id: "ad",
    text: "Andorra",
    callingid: "376",
  },
  {
    id: "ao",
    text: "Angola",
    callingid: "244",
  },
  {
    id: "aq",
    text: "Antarctica",
    callingid: "672",
  },
  {
    id: "ar",
    text: "Argentina",
    callingid: "54",
  },
  {
    id: "am",
    text: "Armenia",
    callingid: "374",
  },
  {
    id: "aw",
    text: "Aruba",
    callingid: "297",
  },
  {
    id: "au",
    text: "Australia",
    callingid: "61",
  },
  {
    id: "at",
    text: "Austria",
    callingid: "43",
  },
  {
    id: "az",
    text: "Azerbaijan",
    callingid: "994",
  },
  {
    id: "bh",
    text: "Bahrain",
    callingid: "973",
  },
  {
    id: "bd",
    text: "Bangladesh",
    callingid: "880",
  },
  {
    id: "by",
    text: "Belarus",
    callingid: "375",
  },
  {
    id: "be",
    text: "Belgium",
    callingid: "32",
  },
  {
    id: "bz",
    text: "Belize",
    callingid: "501",
  },
  {
    id: "bj",
    text: "Benin",
    callingid: "229",
  },
  {
    id: "bt",
    text: "Bhutan",
    callingid: "975",
  },
  {
    id: "bo",
    text: "Bolivia",
    callingid: "591",
  },
  {
    id: "ba",
    text: "Bosnia and Herzegovina",
    callingid: "387",
  },
  {
    id: "bw",
    text: "Botswana",
    callingid: "267",
  },
  {
    id: "bv",
    text: "Bouvet Island",
    callingid: "47",
  },
  {
    id: "br",
    text: "Brazil",
    callingid: "55",
  },
  {
    id: "io",
    text: "British Indian Ocean Territory",
    callingid: "246",
  },
  {
    id: "bn",
    text: "Brunei",
    callingid: "673",
  },
  {
    id: "bg",
    text: "Bulgaria",
    callingid: "359",
  },
  {
    id: "bf",
    text: "Burkina Faso",
    callingid: "226",
  },
  {
    id: "bi",
    text: "Burundi",
    callingid: "257",
  },
  {
    id: "kh",
    text: "Cambodia",
    callingid: "855",
  },
  {
    id: "cm",
    text: "Cameroon",
    callingid: "237",
  },
  {
    id: "cv",
    text: "Cape Verde",
    callingid: "238",
  },
  {
    id: "ky",
    text: "Cayman Islands",
    callingid: "1",
  },
  {
    id: "cf",
    text: "Central African Republic",
    callingid: "236",
  },
  {
    id: "td",
    text: "Chad",
    callingid: "235",
  },
  {
    id: "cl",
    text: "Chile",
    callingid: "56",
  },
  {
    id: "cn",
    text: "China",
    callingid: "86",
  },
  {
    id: "cx",
    text: "Christmas Island",
    callingid: "61",
  },
  {
    id: "cc",
    text: "Cocos Islands",
    callingid: "61",
  },
  {
    id: "co",
    text: "Colombia",
    callingid: "57",
  },
  {
    id: "km",
    text: "Comoros",
    callingid: "269",
  },
  {
    id: "ck",
    text: "Cook Islands",
    callingid: "682",
  },
  {
    id: "cr",
    text: "Costa Rica",
    callingid: "506",
  },
  {
    id: "hr",
    text: "Croatia",
    callingid: "385",
  },
  {
    id: "cu",
    text: "Cuba",
    callingid: "53",
  },
  {
    id: "cw",
    text: "Curacao",
    callingid: "599",
  },
  {
    id: "cy",
    text: "Cyprus",
    callingid: "357",
  },
  {
    id: "cz",
    text: "Czech Republic",
    callingid: "420",
  },
  {
    id: "cd",
    text: "Democratic Republic of the Congo",
    callingid: "243",
  },
  {
    id: "dk",
    text: "Denmark",
    callingid: "45",
  },
  {
    id: "dj",
    text: "Djibouti",
    callingid: "253",
  },
  {
    id: "ec",
    text: "Ecuador",
    callingid: "593",
  },
  {
    id: "eg",
    text: "Egypt",
    callingid: "20",
  },
  {
    id: "sv",
    text: "El Salvador",
    callingid: "503",
  },
  {
    id: "gq",
    text: "Equatorial Guinea",
    callingid: "240",
  },
  {
    id: "er",
    text: "Eritrea",
    callingid: "291",
  },
  {
    id: "ee",
    text: "Estonia",
    callingid: "372",
  },
  {
    id: "et",
    text: "Ethiopia",
    callingid: "251",
  },
  {
    id: "fk",
    text: "Falkland Islands",
    callingid: "500",
  },
  {
    id: "fo",
    text: "Faroe Islands",
    callingid: "298",
  },
  {
    id: "fm",
    text: "Federated States of Micronesia",
    callingid: "691",
  },
  {
    id: "fj",
    text: "Fiji",
    callingid: "679",
  },
  {
    id: "fi",
    text: "Finland",
    callingid: "358",
  },
  {
    id: "fr",
    text: "France",
    callingid: "33",
  },
  {
    id: "gf",
    text: "French Guiana",
    callingid: "594",
  },
  {
    id: "pf",
    text: "French Polynesia",
    callingid: "689",
  },
  {
    id: "tf",
    text: "French Southern Territories",
    callingid: "262",
  },
  {
    id: "ga",
    text: "Gabon",
    callingid: "241",
  },
  {
    id: "gm",
    text: "Gambia",
    callingid: "220",
  },
  {
    id: "ge",
    text: "Georgia",
    callingid: "995",
  },
  {
    id: "de",
    text: "Germany",
    callingid: "49",
  },
  {
    id: "gh",
    text: "Ghana",
    callingid: "233",
  },
  {
    id: "gi",
    text: "Gibraltar",
    callingid: "350",
  },
  {
    id: "gr",
    text: "Greece",
    callingid: "30",
  },
  {
    id: "gl",
    text: "Greenland",
    callingid: "299",
  },
  {
    id: "gp",
    text: "Guadeloupe",
    callingid: "590",
  },
  {
    id: "gt",
    text: "Guatemala",
    callingid: "502",
  },
  {
    id: "gg",
    text: "Guernsey",
    callingid: "44",
  },
  {
    id: "gn",
    text: "Guinea",
    callingid: "224",
  },
  {
    id: "gw",
    text: "Guinea-Bissau",
    callingid: "245",
  },
  {
    id: "gy",
    text: "Guyana",
    callingid: "592",
  },
  {
    id: "ht",
    text: "Haiti",
    callingid: "509",
  },
  {
    id: "hm",
    text: "Heard Island and McDonald Islands",
    callingid: "672",
  },
  {
    id: "hn",
    text: "Honduras",
    callingid: "504",
  },
  {
    id: "hk",
    text: "Hong Kong",
    callingid: "852",
  },
  {
    id: "hu",
    text: "Hungary",
    callingid: "36",
  },
  {
    id: "is",
    text: "Iceland",
    callingid: "354",
  },
  {
    id: "in",
    text: "India",
    callingid: "91",
  },
  {
    id: "id",
    text: "Indonesia",
    callingid: "62",
  },
  {
    id: "ir",
    text: "Iran",
    callingid: "98",
  },
  {
    id: "iq",
    text: "Iraq",
    callingid: "964",
  },
  {
    id: "ie",
    text: "Ireland",
    callingid: "353",
  },
  {
    id: "it",
    text: "Italy",
    callingid: "39",
  },
  {
    id: "ci",
    text: "Ivory Coast",
    callingid: "225",
  },
  {
    id: "jm",
    text: "Jamaica",
    callingid: "1",
  },
  {
    id: "jp",
    text: "Japan",
    callingid: "81",
  },
  {
    id: "je",
    text: "Jersey",
    callingid: "44",
  },
  {
    id: "jo",
    text: "Jordan",
    callingid: "962",
  },
  {
    id: "kz",
    text: "Kazakhstan",
    callingid: "7",
  },
  {
    id: "ke",
    text: "Kenya",
    callingid: "254",
  },
  {
    id: "ki",
    text: "Kiribati",
    callingid: "686",
  },
  {
    id: "xk",
    text: "Kosovo",
    callingid: "383",
  },
  {
    id: "kw",
    text: "Kuwait",
    callingid: "965",
  },
  {
    id: "kg",
    text: "Kyrgyzstan",
    callingid: "996",
  },
  {
    id: "la",
    text: "Laos",
    callingid: "856",
  },
  {
    id: "lv",
    text: "Latvia",
    callingid: "371",
  },
  {
    id: "lb",
    text: "Lebanon",
    callingid: "961",
  },
  {
    id: "ls",
    text: "Lesotho",
    callingid: "266",
  },
  {
    id: "lr",
    text: "Liberia",
    callingid: "231",
  },
  {
    id: "ly",
    text: "Libya",
    callingid: "218",
  },
  {
    id: "li",
    text: "Liechtenstein",
    callingid: "423",
  },
  {
    id: "lt",
    text: "Lithuania",
    callingid: "370",
  },
  {
    id: "lu",
    text: "Luxembourg",
    callingid: "352",
  },
  {
    id: "mo",
    text: "Macau",
    callingid: "853",
  },
  {
    id: "mk",
    text: "Macedonia",
    callingid: "389",
  },
  {
    id: "mg",
    text: "Madagascar",
    callingid: "261",
  },
  {
    id: "mw",
    text: "Malawi",
    callingid: "265",
  },
  {
    id: "my",
    text: "Malaysia",
    callingid: "60",
  },
  {
    id: "mv",
    text: "Maldives",
    callingid: "960",
  },
  {
    id: "ml",
    text: "Mali",
    callingid: "223",
  },
  {
    id: "mt",
    text: "Malta",
    callingid: "356",
  },
  {
    id: "mh",
    text: "Marshall Islands",
    callingid: "692",
  },
  {
    id: "mq",
    text: "Martinique",
    callingid: "596",
  },
  {
    id: "mr",
    text: "Mauritania",
    callingid: "222",
  },
  {
    id: "mu",
    text: "Mauritius",
    callingid: "230",
  },
  {
    id: "yt",
    text: "Mayotte",
    callingid: "262",
  },
  {
    id: "mx",
    text: "Mexico",
    callingid: "52",
  },
  {
    id: "md",
    text: "Moldova",
    callingid: "373",
  },
  {
    id: "mc",
    text: "Monaco",
    callingid: "377",
  },
  {
    id: "mn",
    text: "Mongolia",
    callingid: "976",
  },
  {
    id: "me",
    text: "Montenegro",
    callingid: "382",
  },
  {
    id: "ms",
    text: "Montserrat",
    callingid: "1",
  },
  {
    id: "ma",
    text: "Morocco",
    callingid: "212",
  },
  {
    id: "mz",
    text: "Mozambique",
    callingid: "258",
  },
  {
    id: "mm",
    text: "Myanmar",
    callingid: "95",
  },
  {
    id: "na",
    text: "Namibia",
    callingid: "264",
  },
  {
    id: "nr",
    text: "Nauru",
    callingid: "674",
  },
  {
    id: "np",
    text: "Nepal",
    callingid: "977",
  },
  {
    id: "nl",
    text: "Netherlands",
    callingid: "31",
  },
  {
    id: "nc",
    text: "New Caledonia",
    callingid: "687",
  },
  {
    id: "nz",
    text: "New Zealand",
    callingid: "64",
  },
  {
    id: "ni",
    text: "Nicaragua",
    callingid: "505",
  },
  {
    id: "ne",
    text: "Niger",
    callingid: "227",
  },
  {
    id: "ng",
    text: "Nigeria",
    callingid: "234",
  },
  {
    id: "nu",
    text: "Niue",
    callingid: "683",
  },
  {
    id: "nf",
    text: "Norfolk Island",
    callingid: "672",
  },
  {
    id: "kp",
    text: "North Korea",
    callingid: "850",
  },
  {
    id: "mp",
    text: "Northern Mariana Islands",
    callingid: "1",
  },
  {
    id: "no",
    text: "Norway",
    callingid: "47",
  },
  {
    id: "om",
    text: "Oman",
    callingid: "968",
  },
  {
    id: "pk",
    text: "Pakistan",
    callingid: "92",
  },
  {
    id: "pa",
    text: "Panama",
    callingid: "507",
  },
  {
    id: "pg",
    text: "Papua New Guinea",
    callingid: "675",
  },
  {
    id: "py",
    text: "Paraguay",
    callingid: "595",
  },
  {
    id: "pe",
    text: "Peru",
    callingid: "51",
  },
  {
    id: "ph",
    text: "Philippines",
    callingid: "63",
  },
  {
    id: "pn",
    text: "Pitcairn",
    callingid: "870",
  },
  {
    id: "pl",
    text: "Poland",
    callingid: "48",
  },
  {
    id: "pt",
    text: "Portugal",
    callingid: "351",
  },
  {
    id: "pr",
    text: "Puerto Rico",
    callingid: "1",
  },
  {
    id: "qa",
    text: "Qatar",
    callingid: "974",
  },
  {
    id: "cg",
    text: "Republic of the Congo",
    callingid: "242",
  },
  {
    id: "re",
    text: "Reunion",
    callingid: "262",
  },
  {
    id: "ro",
    text: "Romania",
    callingid: "40",
  },
  {
    id: "ru",
    text: "Russia",
    callingid: "7",
  },
  {
    id: "rw",
    text: "Rwanda",
    callingid: "250",
  },
  {
    id: "bl",
    text: "Saint Barthelemy",
    callingid: "590",
  },
  {
    id: "sh",
    text: "Saint Helena",
    callingid: "290",
  },
  {
    id: "kn",
    text: "Saint Kitts and Nevis",
    callingid: "1",
  },
  {
    id: "lc",
    text: "Saint Lucia",
    callingid: "1",
  },
  {
    id: "mf",
    text: "Saint Martin",
    callingid: "590",
  },
  {
    id: "pm",
    text: "Saint Pierre and Miquelon",
    callingid: "508",
  },
  {
    id: "vc",
    text: "Saint Vincent and the Grenadines",
    callingid: "1",
  },
  {
    id: "ws",
    text: "Samoa",
    callingid: "685",
  },
  {
    id: "sm",
    text: "San Marino",
    callingid: "378",
  },
  {
    id: "st",
    text: "Sao Tome and Principe",
    callingid: "239",
  },
  {
    id: "sa",
    text: "Saudi Arabia",
    callingid: "966",
  },
  {
    id: "sn",
    text: "Senegal",
    callingid: "221",
  },
  {
    id: "rs",
    text: "Serbia",
    callingid: "381",
  },
  {
    id: "sc",
    text: "Seychelles",
    callingid: "248",
  },
  {
    id: "sl",
    text: "Sierra Leone",
    callingid: "232",
  },
  {
    id: "sg",
    text: "Singapore",
    callingid: "65",
  },
  {
    id: "sx",
    text: "Sint Maarten",
    callingid: "3",
  },
  {
    id: "sk",
    text: "Slovakia",
    callingid: "421",
  },
  {
    id: "si",
    text: "Slovenia",
    callingid: "386",
  },
  {
    id: "sb",
    text: "Solomon Islands",
    callingid: "677",
  },
  {
    id: "so",
    text: "Somalia",
    callingid: "252",
  },
  {
    id: "za",
    text: "South Africa",
    callingid: "27",
  },
  {
    id: "gs",
    text: "South Georgia and the South Sandwich Islands",
    callingid: "500",
  },
  {
    id: "kr",
    text: "South Korea",
    callingid: "82",
  },
  {
    id: "ss",
    text: "South Sudan",
    callingid: "211",
  },
  {
    id: "es",
    text: "Spain",
    callingid: "34",
  },
  {
    id: "lk",
    text: "Sri Lanka",
    callingid: "94",
  },
  {
    id: "ps",
    text: "State of Palestine",
    callingid: "970",
  },
  {
    id: "sd",
    text: "Sudan",
    callingid: "249",
  },
  {
    id: "sr",
    text: "Suriname",
    callingid: "597",
  },
  {
    id: "sj",
    text: "Svalbard and Jan Mayen",
    callingid: "47",
  },
  {
    id: "sz",
    text: "Swaziland",
    callingid: "268",
  },
  {
    id: "se",
    text: "Sweden",
    callingid: "46",
  },
  {
    id: "ch",
    text: "Switzerland",
    callingid: "41",
  },
  {
    id: "sy",
    text: "Syria",
    callingid: "963",
  },
  {
    id: "tw",
    text: "Taiwan",
    callingid: "886",
  },
  {
    id: "tj",
    text: "Tajikistan",
    callingid: "992",
  },
  {
    id: "tz",
    text: "Tanzania",
    callingid: "255",
  },
  {
    id: "th",
    text: "Thailand",
    callingid: "66",
  },
  {
    id: "tl",
    text: "Timor-Leste",
    callingid: "670",
  },
  {
    id: "tg",
    text: "Togo",
    callingid: "228",
  },
  {
    id: "tk",
    text: "Tokelau",
    callingid: "690",
  },
  {
    id: "to",
    text: "Tonga",
    callingid: "676",
  },
  {
    id: "tt",
    text: "Trinidad and Tobago",
    callingid: "1",
  },
  {
    id: "tn",
    text: "Tunisia",
    callingid: "216",
  },
  {
    id: "tr",
    text: "Turkey",
    callingid: "90",
  },
  {
    id: "tm",
    text: "Turkmenistan",
    callingid: "993",
  },
  {
    id: "tc",
    text: "Turks and Caicos Islands",
    callingid: "1",
  },
  {
    id: "tv",
    text: "Tuvalu",
    callingid: "688",
  },
  {
    id: "ug",
    text: "Uganda",
    callingid: "256",
  },
  {
    id: "ua",
    text: "Ukraine",
    callingid: "380",
  },
  {
    id: "ae",
    text: "United Arab Emirates",
    callingid: "971",
  },
  {
    id: "gb",
    text: "United Kingdom",
    callingid: "44",
  },
  {
    id: "us",
    text: "United States",
    callingid: "1",
  },
  {
    id: "um",
    text: "United States Minor Outlying Islands",
    callingid: "1",
  },
  {
    id: "uy",
    text: "Uruguay",
    callingid: "598",
  },
  {
    id: "uz",
    text: "Uzbekistan",
    callingid: "998",
  },
  {
    id: "vu",
    text: "Vanuatu",
    callingid: "678",
  },
  {
    id: "va",
    text: "Vatican City State",
    callingid: "39",
  },
  {
    id: "ve",
    text: "Venezuela (Bolivarian Republic of)",
    callingid: "58",
  },
  {
    id: "vn",
    text: "Viet Nam",
    callingid: "84",
  },
  {
    id: "vg",
    text: "Virgin Islands (British)",
    callingid: "1",
  },
  {
    id: "vi",
    text: "Virgin Islands (U.S.)",
    callingid: "1",
  },
  {
    id: "wf",
    text: "Wallis and Futuna",
    callingid: "681",
  },
  {
    id: "eh",
    text: "Western Sahara",
    callingid: "212",
  },
  {
    id: "ye",
    text: "Yemen",
    callingid: "967",
  },
  {
    id: "zm",
    text: "Zambia",
    callingid: "260",
  },
  {
    id: "zw",
    text: "Zimbabwe",
    callingid: "263",
  },
];
