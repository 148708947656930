import React, { useState } from "react";
import { Document, Page,pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
export const SharedPDF = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Document
      file={{
        url: pdf,
      }}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "8.26/11.69",
            width: "min(100%, 700px)",
            background: "rgba(0, 133, 255, 0.1)",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      }
      error={
        <div
          style={{
            padding: "4rem",
            aspectRatio: "8.26/11.69",
            width: "100%",
            background: `rgba(255, 5 , 5, 0.3)`,
          }}
        >
          Error Loading PDF
        </div>
      }
      onLoadError={(error) => console.log(error)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          className={`mt-3 overflow-hidden shadow rounded-3`}
          pageNumber={index + 1}
        />
      ))}
    </Document>
  );
};
