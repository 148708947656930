import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/buttons/customButton";
import { UploadFile } from "../../../components/form/upload_file";
import { PageLayout } from "../../../components/layout/pageLayout";
import { SummeryLayout } from "../../../components/summary-layout";
import { TransparentShadowSection } from "../../../components/transparent-shadow";
import { handleError } from "../../../helper";
import { ApiRequests } from "../../../service/ApiRequests";

export default function CreateSummaryPage() {
  const [summary, setSummary] = useState({ output: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useNavigate();

  const [file, setFile] = useState<File>();
  async function createSummary() {
    if (file) {
      if(!(file.name.endsWith(".docx") || file.name.endsWith(".DOCX") || file.name.endsWith(".pdf") || file.name.endsWith(".PDF") )){
        return toast.error("File must be pdf or docx"+file.name )
      }
      try {
        const uri = await ApiRequests.uploadSummary(file);
        const res = await ApiRequests.createSummary({ uri });
        setSummary(res.data);
        setLoading(false);
        return res.data;
      } catch (error) {
        setLoading(false);
        handleError({ error, setError, router });
        // setError(error?.response?.data?.message ?? "Something went wrong")
      }
    } else {
      toast.error("Select File First!");
    }
  }
  return (
    <PageLayout title="> Gestión Documentos / Generar nuevo resumen">
      <div className="row mb-4 g-4 align-items-lg-center">
        <div className="col-lg-9">
          <TransparentShadowSection>
            Sube tu documento legal para que podamos resumirlo con palabras mas
            sencillas de entender.
          </TransparentShadowSection>
        </div>
        <div className="col-lg-3">
          <UploadFile
            handleFile={(file) => {
              setFile(file);
            }}
          />
        </div>
      </div>
      {error}
      <CustomButton
        onClick={createSummary}
        value={loading ? "Generar..." : "Generar"}
        className="mb-4 rounded-pill btn btn-primary"
      />
      <SummeryLayout text={summary?.output ?? ""}>
        {/* <q> */}
        {summary?.output ?? ""}
        {/* </q> */}
      </SummeryLayout>
    </PageLayout>
  );
}
