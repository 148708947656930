import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomButton } from "../../../components/buttons/customButton";
import { CheckboxField } from "../../../components/form/checkbox_field";
import { DatePickerField } from "../../../components/form/date_picker";
import { InputField } from "../../../components/form/input_field";
import { SelectField } from "../../../components/form/select_field";
import { PageLayout } from "../../../components/layout/pageLayout";
import { ApiRequests } from "../../../service/ApiRequests";
import { CreateLegalDocument } from "../../../types/auth";
import { contractTypes } from "../../../util-constants";

const ContractInputField = InputField<CreateLegalDocument>;
const sampleData: CreateLegalDocument = {
  type: "Contrato de compraventa",
  signatureDate: "2023-12-20",
  profileData:
    "Name: Sarah Johnson\nAddress: 123 Main Street, Anytown, USA\nPhone number: (555) 555-1212\nEmail: sarah.johnson@email.com\nCompany: Johnson's Car Motors\nPosition: Owner/Manager",
  dataOfTheOtherParty:
    "Name: John Smith\nAddress: 123 Main Street\nCity: Anytown\nState: CA\nZip Code: 12345\nPhone Number: 555-555-1234\nEmail: johnsmith@email.com",
  useProfileData: false,
  objectOfTheContract:
    "Product/Service Purchased: 2023 Toyota Camry XSE\nPrice: $32,000\nPayment Method: Cash\nDelivery Date: May 1, 2023\nWarranty: 3 years or 36,000 miles (whichever comes first)\nReturn Policy: No returns or exchanges, all sales are final.",
  otherClauses:
    "Payment Terms: The buyer agrees to pay a down payment of 20% of the purchase price within 7 days of the agreement date, and the remaining balance will be paid by certified check at the closing.\n\nDelivery Terms: The seller agrees to deliver the property to the buyer on the date of closing, at the location specified in the agreement. The buyer is responsible for any shipping or handling fees associated with the transfer of the property.\n\nDispute Resolution: The parties agree to try to resolve any disputes or disagreements through mediation before pursuing any legal action.\n\nGoverning Law: This agreement shall be governed by and construed in accordance with the laws of the State of California.\n\nIndemnification: The buyer agrees to indemnify and hold harmless the seller from any losses or damages resulting from any breach of the agreement by the buyer.\n\nConfidentiality: The parties agree to keep all information related to the transaction confidential and not to disclose it to any third party without prior written consent.\n\nAssignment: Neither party may assign its rights or obligations under this agreement without the prior written consent of the other party.",
  expirationDate: "2023-05-04",
};

const sampleData2: CreateLegalDocument = {
  type: "Contrato de préstamo",
  signatureDate: "2023-12-20",
  profileData:
    "Name: John Smith\nAddress: 123 Main Street\nCity: Anytown\nState: California\nZip Code: 12345\nPhone: (555) 123-4567\nEmail: johnsmith@email.com",
  dataOfTheOtherParty:
    "Name: Jane Doe\nAddress: 456 Oak Avenue\nCity: Otherville\nState: California\nZip Code: 54321\nPhone: (555) 987-6543\nEmail: janedoe@email.com",
  useProfileData: false,
  objectOfTheContract: `Loan Amount: $10,000
    Loan Term: 2 years
    Interest Rate: 5% per annum
    Repayment Terms: Monthly payments of $450`,
  otherClauses: `Loan Purpose: The Borrower acknowledges that the loan amount of $10,000 shall be used solely for home renovations and agrees to provide documentation to the Lender upon request.\n\nInterest Rate: The Borrower agrees to pay an annual interest rate of 5% on the outstanding principal balance. Interest shall be calculated and accrued monthly.\n\nRepayment Terms: The Borrower shall make monthly repayments of $450, due on the 15th of each month, beginning on June 15, 2023. Payments shall be made via electronic bank transfer.\n\nPrepayment: The Borrower has the right to prepay the loan, in part or in full, at any time without incurring any prepayment penalties.\n\nLate Payments: In the event that the Borrower fails to make a payment within 10 days of the due date, a late fee of $50 shall be charged for each occurrence.\n\nDefault: The Borrower shall be considered in default if they fail to make consecutive payments for a period of 60 days. In the event of default, the Lender shall have the right to take appropriate legal actions to recover the outstanding amount.\n\nCollateral: This loan is unsecured, and no collateral is provided.\n\nGoverning Law: This Agreement shall be governed by and construed in accordance with the laws of the state of California. Any disputes arising from this Agreement shall be subject to the exclusive jurisdiction of the courts in California.\n\nEntire Agreement: This Agreement constitutes the entire agreement between the Lender and the Borrower and supersedes any prior discussions or agreements, whether written or oral, relating to the loan.\n\nSeverability: If any provision of this Agreement is deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect.`,
  expirationDate: "2025-05-04",
};

export default function NewContractPage() {
  const [loading, setLoadng] = useState(false);
  const { handleSubmit, control, watch } = useForm<CreateLegalDocument>({
    defaultValues:
      process.env.NODE_ENV === "development"
        ? sampleData
        : {
            type: contractTypes[0].value,
            profileData: "",
            dataOfTheOtherParty: "",
            useProfileData: true,
            objectOfTheContract: "",
            otherClauses: "",
          },
  });
  const submit: SubmitHandler<CreateLegalDocument> = async (values) => {
    try {
      setLoadng(true);
      await ApiRequests.generate(values);
      setLoadng(false);
    } catch (error) {
      setLoadng(false);
    }
  };
  const useProfileDataBool = watch("useProfileData");
  return (
    <PageLayout title="> Gestión Documentos / Generar nuevo contrato">
      <form className="row g-5 g-md-4" onSubmit={handleSubmit(submit)}>
        <div className="col-12">
          <SelectField
            control={control}
            name={"type"}
            label={"Elige tipo contrato"}
            options={contractTypes}
          />
        </div>
        <div className="col-12">
          <DatePickerField
            control={control}
            label={"Vencimiento"}
            name="expirationDate"
          />
        </div>
        <div className="col-12">
          <CheckboxField
            control={control}
            name={"useProfileData"}
            checked={useProfileDataBool}
            id="checkbox"
            type="checkbox"
            label={"¿Usar tus datos de perfil?"}
            paragraph="Se usarán tus datos registrados como parte para generar el contrato."
          />
        </div>
        {!useProfileDataBool && (
          <div className="col-12">
            <ContractInputField
              label={"Información personal"}
              placeholder="Nombre y Apellidos, Nombre de la empresa, Dirección completa, DNI or CIF"
              textArea={true}
              fullWidth={true}
              name="profileData"
              control={control}
            />
          </div>
        )}
        <div className="col-12">
          <ContractInputField
            label={"Datos de la otra parte"}
            placeholder="Escribe nombre completo o empresa y representante, DNI o CIF y dirección completa"
            textArea={true}
            fullWidth={true}
            name="dataOfTheOtherParty"
            control={control}
          />
        </div>
        <div className="col-12">
          <ContractInputField
            control={control}
            label={"Objeto del contrato"}
            placeholder="Describe el objeto del contrato, precio y forma de pago. "
            textArea={true}
            fullWidth={true}
            name="objectOfTheContract"
          />
        </div>
        <div className="col-12">
          <ContractInputField
            control={control}
            label={"Otras clausulas"}
            placeholder="Si tiene vencimiento estipulado, clausulas especiales, anexos que quieras incluir...."
            textArea={true}
            fullWidth={true}
            name="otherClauses"
          />
        </div>
        <div className="col-12">
          <DatePickerField
            control={control}
            label={"Fecha de firma"}
            name="signatureDate"
          />
        </div>
        <div className="text-center col-12">
          <CustomButton
            disabled={loading}
            className="rounded-pill btn btn-dark btn-sm"
            type="submit"
            value={loading ? "Generar..." : "Generar"}
          />
        </div>
      </form>
    </PageLayout>
  );
}
