import { FormFieldLayout } from "../form_field_layout";

import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

interface FieldProps {
  label?: string;
  fullWidth?: boolean;
  textArea?: boolean;
  control?: any;
}
type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & FieldProps;

type TextAreaFieldProps = React.InputHTMLAttributes<HTMLTextAreaElement> &
  FieldProps;
type CommonProps = InputFieldProps | TextAreaFieldProps;
function isTextArea(e: CommonProps): e is TextAreaFieldProps {
  return (e as TextAreaFieldProps).textArea === true;
}

export const DatePickerField: FC<InputFieldProps | TextAreaFieldProps> = ({
  label,
  fullWidth,
  textArea,
  ...props
}) => {
  if (isTextArea({ label, fullWidth, textArea, ...props })) {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <input
              {...(props as InputFieldProps)}
              type={"date"}
              className="shadow form-control"
              placeholder={label}
            />
          )}
        />
      </FormFieldLayout>
    );
  } else {
    return (
      <FormFieldLayout fullWidth={fullWidth} label={label} id={props.id}>
        <Controller
          name={props.name}
          control={props.control}
          render={({ field, fieldState }) => (
            <input
              {...(props as InputFieldProps)}
              {...field}
              type="date"
              className="shadow form-control"
            />
          )} />
      </FormFieldLayout>
    );
  }
};
