import { CustomButton } from "components/buttons/customButton";
import { FC } from "react";
import { toast } from "react-hot-toast";
import styles from "styles/summary_layout.module.scss";
import FontsPopup from "utils/FontsPopup";
interface SummeryLayoutProps {
  shareButton?: boolean;
  children: React.ReactNode;
  text?: string;
  id?: string;
  title?: string;
}

export const SummeryLayout: FC<SummeryLayoutProps> = ({
  shareButton,
  children,
  text,
  id,
  title,
}) => {
  async function downloadPdf() {
    toast.loading("Descargando...", { id: "downloadPdf" });
    const { default: jsPDF, AcroFormTextField } = await import("jspdf");
    const doc = new jsPDF({
      orientation: "p",
    });
    const fontSize = 12; // Set font size to 12
    const lineHeight = 7; // Set line height to 15

    doc.setFontSize(fontSize);
    const newText = text.split("\n");
    const heading = newText.shift();
    // Split text into array of lines
    const lines = doc.splitTextToSize(
      newText.join("\n"),
      doc.internal.pageSize.width - 20
    );
    // Set initial y position
    let y = 20;
    doc.setFontSize(20);
    const headingLines = doc.splitTextToSize(
      heading,
      doc.internal.pageSize.width - 20
    );
    for (let i = 0; i < headingLines.length; i++) {
      if (y > doc.internal.pageSize.height - 20) {
        // Add new page if current page is full
        doc.addPage();
        y = 20;
      }
      doc.setFontSize(20);
      doc.setFont(undefined, "bold");
      doc.text(headingLines[i], 10, y);
      y += lineHeight; // Increase y position by line height
    }
    doc.setFontSize(fontSize);
    // Loop through lines and add to PDF
    let fieldCount = 0;
    for (let i = 0; i < lines.length; i++) {
      if (y > doc.internal.pageSize.height - 20) {
        // Add new page if current page is full
        doc.addPage();
        y = 20;
      }
      doc.setFontSize(fontSize);
      doc.setFont(undefined, "normal");
      if (lines[i].trim()[0] === "_" && lines[i].trim().at(-1) === "_") {
        fieldCount++;
        const field = new AcroFormTextField();
        field.x = 10;
        field.y = y - 10;
        field.width = 100;
        field.height = lineHeight;
        field.fontSize = fontSize;
        field.fieldName = fieldCount === 1 ? "seller" : "buyer";
        doc.addField(field);
      } else {
        doc.text(lines[i], 10, y);
      }
      y += lineHeight; // Increase y position by line height
    }
    // Save the document as a file
    doc.save(`${title}.pdf`);
    toast.success("Descargado", { id: "downloadPdf" });
  }
  const downloadWord = async () => {
    toast.loading("Descargando...", { id: "downloadWord" });
    const { Document, Paragraph, Packer, HeadingLevel, SimpleField, TextRun } =
      await import("docx");
    const fileSaver = (await import("file-saver")).default;
    const doc = new Document({
      sections: [
        {
          children: text.split("\n").map((text, i) => {
            const Text = new TextRun({
              text,
              bold: i === 0,
              font: "Calibri",
            });
            return new Paragraph({
              heading: i === 0 ? HeadingLevel.HEADING_1 : undefined,
              children: [Text],
            });
          }),
        },
      ],
      title: text.split("\n")[0],
    });

    const blob = await Packer.toBlob(doc);
    fileSaver.saveAs(blob, `${title}.docx`);
    toast.success("Descargado", { id: "downloadWord" });
  };
  return (
    <div className="row g-5 align-items-center">
      <div className="col-lg-9">
        <section
          id={"printablediv"}
          className={`p-4 shadow rounded-3 bg-white ${styles.summary_layout_section}`}
        >
          {children}
        </section>
      </div>
      <div className="col-lg-3">
        <div className="row g-3">
          <div className="col-6 col-md-4 col-lg-12">
            <CustomButton
              onClick={() => downloadPdf()}
              value="Imprmir"
              className="btn btn-primary w-100"
            />
          </div>
          <div className="col-6 col-md-4 col-lg-12">
            <CustomButton
              onClick={downloadWord}
              value="Exportar a word"
              className="btn btn-primary w-100"
            />
          </div>
          {shareButton && <FontsPopup text={text} />}
          {!id && (
            <div className="col-6 col-md-4 col-lg-12">
              <CustomButton
                value="Guardar en tu área"
                className="btn btn-dark w-100"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
