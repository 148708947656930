import React, { Suspense } from "react";
import { SharedPDF } from "./pdf";
import { Await, LoaderFunction, defer, useLoaderData } from "react-router-dom";
import { ApiRequests } from "service/ApiRequests";
import { CustomButton } from "components/buttons/customButton";
import CircleLogo from "assets/images/logo/favicon.png";
import { toast } from "react-hot-toast";
export default function SharedContractDocument() {
  const data: any = useLoaderData();
  return (
    <div>
      <header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 100,
          background: "white",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          height: "90px",
        }}
      >
        <div className="container py-2">
          <div className="row">
            <div className="col-6">
              <img src={CircleLogo} alt="logo" height={75} width={75} />
            </div>
            <div className="col-6 text-right d-flex justify-content-between align-items-center">
              <CustomButton
                value={"Copy Link"}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast.success("Link Copied");
                }}
              />
              <CustomButton
                value={"Share Link"}
                onClick={() => {
                  navigator.share({
                    title: "Share Link",
                    text: "Share Link",
                    url: window.location.href,
                  });
                  toast.success("Link Shared");
                }}
              />
              <Suspense
                fallback={
                  <CustomButton
                    value={
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only"></span>
                      </>
                    }
                    className="btn btn-primary"
                    type="button"
                    disabled
                  ></CustomButton>
                }
              >
                <Await
                  errorElement={
                    <CustomButton
                      value={"Download"}
                      onClick={() => {}}
                      className="btn btn-primary"
                      type="button"
                      disabled
                    />
                  }
                  resolve={data?.pdf}
                >
                  {(pdf) => (
                    <CustomButton
                      value={"Print"}
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = pdf;
                        link.target = "_blank";
                        link.click();
                      }}
                      className="btn btn-primary"
                      type="button"
                    />
                  )}
                </Await>
              </Suspense>
            </div>
          </div>
        </div>
      </header>
      <div
        className="container"
        style={{
          marginTop: "90px",
        }}
      >
        <div
          className="mx-auto"
          style={{
            display: "flex",
            width: "min(100%, 595px)",
          }}
        >
          <Suspense
            fallback={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  aspectRatio: "8.26/11.69",
                  width: "100%",
                  background: "rgba(0, 133, 255, 0.1)",
                }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            }
          >
            <Await
              errorElement={
                <div
                  style={{
                    padding: "4rem",
                    aspectRatio: "8.26/11.69",
                    width: "100%",
                    background: `rgba(255, 5 , 5, 0.3)`,
                  }}
                >
                  Error Loading PDF
                </div>
              }
              resolve={data?.pdf}
            >
              {(pdf) => <SharedPDF pdf={pdf} />}
            </Await>
          </Suspense>
        </div>
        h
      </div>
    </div>
  );
}

const loader: LoaderFunction = ({ params }) => {
  return defer({
    pdf: ApiRequests.getPDF(params.id),
  });
};

export { loader as sharedDocumentLoader };
