import { Suspense } from "react";
import { toast } from "react-hot-toast";
import { BiHome } from "react-icons/bi";
import { Await, Link, defer, useLoaderData } from "react-router-dom";
import { CustomButton } from "../../components/buttons/customButton";
import { DocumentCard } from "../../components/document-card";
import { InputRangeSlider } from "../../components/form/input_range";
import { PageLayout } from "../../components/layout/pageLayout";
import { PdfCard } from "../../components/pdf-card";
import { TransparentShadowSection } from "../../components/transparent-shadow";
import { ApiRequests } from "../../service/ApiRequests";
export default function HomePage() {
  const data: any = useLoaderData();
  return (
    <PageLayout
      title={[
        <BiHome key={"1"} color="#ccc" size={30} className="me-2" />,
        "Tu posición actual",
      ]}
    >
      <div className="row g-5 justify-content-between">
        <Suspense
          fallback={
            <div className="d-flex justify-content-center">
              <div className="spinner-border m-auto" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        >
          <Await resolve={data?.counts}>
            {(data) => {
              return <MonthlyUsage {...data} />;
            }}
          </Await>
        </Suspense>
      </div>
      <div className="row justify-content-between">
        <section className="col-lg-6 px-lg-4 text-center">
          <TransparentShadowSection className={"bg-white rounded-3 mb-4"}>
            Tus últimos documentos/contratos
          </TransparentShadowSection>
          {/* contracts */}
          <Suspense
            fallback={
              <div className="d-flex justify-content-center">
                <div className="spinner-border m-auto" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          >
            <Await resolve={data?.data}>
              {(data) => {
                return <Cards {...data} />;
              }}
            </Await>
          </Suspense>
        </section>
        <section className="col-lg-6 px-lg-4 text-center">
          <TransparentShadowSection className={"bg-white rounded-3 mb-4"}>
            Proximas fechas de vencimiento
          </TransparentShadowSection>
          <Suspense
            fallback={
              <div className="d-flex justify-content-center">
                <div className="spinner-border m-auto" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          >
            <Await resolve={data?.dataWithExpiryDate}>
              {(pdfCards) => {
                return (
                  <div className="row g-4">
                    {pdfCards.map((data, index) => (
                      <div
                        className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-4"
                        key={index}
                      >
                        <Link to={`/user/contract/${data.id}`}>
                          <PdfCard
                            date={data.expirationDate || data.expiration_date}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Await>
          </Suspense>
        </section>
      </div>
    </PageLayout>
  );
}

async function getDocuments({ page = 1 }: { page?: number }) {
  try {
    const res = (await ApiRequests.getDocuments({ page }))?.data ?? {};

    return res;
  } catch (error) {
    toast.error(
      error?.response?.data?.message ?? error.message ?? "Something went wrong!"
    );
    throw error;
  }
}
async function getContracts({ page = 1 }: { page: number }) {
  try {
    const res = (await ApiRequests.getContracts({ page }))?.data ?? {};
    return res;
  } catch (error) {
    toast.error(
      error?.response?.data?.message ?? error.message ?? "Something went wrong!"
    );
    throw error;
  }
}
async function getSummaries({ page = 1 }: { page: number }) {
  try {
    const res = (await ApiRequests.getSummaries({ page }))?.data ?? {};
    return res;
  } catch (error) {
    toast.error(
      error?.response?.data?.message ?? error.message ?? "Something went wrong!"
    );
    throw error;
  }
}

const loader = () => {
  return defer({
    data: Promise.all([
      getDocuments({ page: 1 }),
      getContracts({ page: 1 }),
      getSummaries({ page: 1 }),
    ]).then((res) => ({
      documents: res[0],
      contracts: res[1],
      summaries: res[2],
    })),
    counts: ApiRequests.getSubscriptionCounts(),
    dataWithExpiryDate: getContractWithExpiryDate(),
  });
};

const getContractWithExpiryDate = async () => {
  try {
    const res = (
      await ApiRequests.getContracts({
        sortBy: "expirationDate:desc",
        limit: 9,
      })
    ).data.results;
    console.log(res);
    return res;
  } catch (error) {
    toast.error(
      error?.response?.data?.message ?? error.message ?? "Something went wrong!"
    );
    throw error;
  }
};
const Cards = ({ documents, contracts, summaries }) => {
  return (
    <div className="row g-4">
      {contracts?.results?.map((data, index) => (
        <div className="col-6 col-md-4 col-lg-6" key={index}>
          <DocumentCard data={{ ...data, dataType: "contract" }} />
        </div>
      ))}
      {summaries?.results?.map((data, index) => (
        <div className="col-6 col-md-4 col-lg-6" key={index}>
          <DocumentCard data={{ ...data, dataType: "signature" }} />
        </div>
      ))}
      {documents?.results?.map((data, index) => (
        <div className="col-6 col-md-4 col-lg-6" key={index}>
          <DocumentCard data={{ ...data, dataType: "upload" }} />
        </div>
      ))}
    </div>
  );
};

export { loader as userLoader };

const MonthlyUsage = ({ used, total }) => {
  const monthSection = [
    {
      title: "Contratos generados mes curso",
      count: used.contracts ?? 0,
      total: total.contracts ?? 0,
      path: "/user/new-contract",
      linkName: "Crear nuevo contrato",
    },
    {
      title: "Resumenes generados mes curso",
      count: used.summeries ?? 0,
      total: total.summeries ?? 0,
      path: "/user/create-summary",
      linkName: "Crear nuevo resumen",
    },
  ];
  return (
    <>
      {monthSection.map((data, index) => (
        <section className="col-lg-6 col-xl-5 mb-5" key={index}>
          <div className="text-center">
            <TransparentShadowSection className={"bg-white rounded-3 mb-4"}>
              {data.title}
            </TransparentShadowSection>
            <div className="mx-auto col-8">
              <InputRangeSlider maxValue={data.total} value={data.count} />
            </div>
            <Link to={data.path}>
              <CustomButton
                value={data.linkName}
                className="btn btn-dark rounded-pill"
              />
            </Link>
          </div>
        </section>
      ))}
    </>
  );
};
