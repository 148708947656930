import ReactInputVerificationCode from "react-input-verification-code";

export default function VerificationCode() {
  const onComplete = () => {};
  return (
    <>
      <div>
        <p>Enter the code below</p>
        <ReactInputVerificationCode
          autoFocus
          length={6}
          placeholder=""
          onCompleted={onComplete}
        />
      </div>
    </>
  );
}
